import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMPlacard from '../components/FPM-Placard';
import image1src from '../images/cafe-pos-brochure_bg.jpg';

const CoffeeShopPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Coffee Shop POS' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Jumpstart Every Day With Coffee Shop POS</h2>
						<p>Many people use coffee to help jumpstart their days, and long lines are the last thing they want to see. Coffee shops are different than traditional restaurants because they deal with high volume, peak service times. Customers are usually on the way to work, so they want to get their coffee and breakfast as fast as possible. Focus’ Coffee Shop POS gives baristas the power to keep lines moving fast by streamlining services with its intelligent interface.</p>
						<p>Optimizing your stock and selection has never been easier! Coffee Shop POS allows you to keep track of traffic, sales, food production, and inventory. Once you use our POS system to optimize your inventory and staff, you will be able to increase sales and serve more customers. Our Coffee Shop POS will help you provide more consistent and speedier service, making your shop more attractive to people going through their morning routines.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='At1ehmE6bq4' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image1src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Café POS Brochure</h2>
					<a className='widget-link white' href='https://focuspos.com/wp-content/uploads/2014/02/cafe_service1.pdf' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row-3' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Customer Interfaces</h2>
						<ul>
							<li>Personalized Menu Boards</li>
							<li>Customer Rear Displays</li>
							<li>Kiosk Mode</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Packaged Commands</h2>
						<ul>
							<li>Auto Combos</li>
							<li>Quick Modifications</li>
							<li>Quick Size Selections</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Suggested Selling</h2>
						<ul>
							<li>On-Screen Upsell Notification</li>
							<li>Customer Display Upsell Images</li>
							<li>Walk-Through Upselling</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default CoffeeShopPOS;