import {useState, useRef} from 'react';
import FPMThumbBox from './FPM-ThumbBox'
import FPMYouTubeViewerOverlay from './FPM-YouTubeViewerOverlay';

const FPMYouTubeGalery = ({videoList}) => {
	const [active, setActive] = useState(false);
	const [videoId, setVideoId] = useState(null);
	const viewerRef = useRef(null);

	console.log(`FPMYouTubeGalery.Reference:`);
	console.log(viewerRef);

	const openViewer = (event, videoId) => {
		event.preventDefault();
		setVideoId(videoId);
		setActive(true);
		console.log('OpenViewer');
	}
	
	const toggleViewer = () => {
		setActive(!active);
	}

	const closeViewer = (event, viewerRef) => {
		if (viewerRef && viewerRef.current && viewerRef.current.contentWindow) {
            viewerRef.current.contentWindow.postMessage(`{"event":"command","func":"stopVideo","args":""}`, '*');
        }
		setVideoId(null);
		setActive(false);
    }

	return(
		<div ref={viewerRef} className='wall-auto' style={{gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))', columnGap: '40px'}}>
			{videoList.title != null && <div className='wall-title'><h2>{videoList.title}</h2></div>}
			{
				videoList.list.map((item, index) => {
					return(
						<FPMThumbBox key={index} video={item} onPlay={openViewer}/>
					);
				})
			}
			<FPMYouTubeViewerOverlay 
				ref={viewerRef}
				active={active}
				videoId={videoId}
				onClose={closeViewer}
			/>
		</div>
	)
}

export default FPMYouTubeGalery
