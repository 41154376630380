import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/table-service-brochure_bg.jpg';
import glimg1src from '../images/mexican-restaurant-screenshot1-1024x768.png';
import glimg2src from '../images/mexican-restaurant-screenshot2-1024x768.png';
import glimg3src from '../images/mexican-restaurant-screenshot3-1024x768.png';
import glimg4src from '../images/mexican-restaurant-screenshot4-1024x768.png';
import glimg5src from '../images/mexican-restaurant-screenshot5-1024x768.png';
import glimg6src from '../images/mexican-restaurant-screenshot6-1024x768.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const MexRestPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Mexican Restaurant POS' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Only Settle for an Authentic Mexican Restaurant POS</h2>
						<p>The world’s appetite for Mexican food has been growing steadily over the years. Sales for Mexican restaurants are up almost 10 percent since 2012. As more and more restaurants are pop up, the need for dedicated Mexican food POS software continues to grow. Many authentic Mexican restaurants employ workers who speak Spanish as a first language, so offering a bi-lingual system is a requirement.</p>
						<p>Finding a point of sale system that can accommodate English and Spanish speaking employees and customers is critical. Focus’s Mexican restaurant POS software does this, which means servers can complete custom orders, print receipts, and checkout customers faster than ever.</p>
						<p>Protecting the authenticity of Mexican restaurants is paramount, but it is also vital that POS systems offer other beneficial features. Mexican restaurant POS solutions must also be able to track inventory and optimize labor. In order to make a profit, restaurants need to be efficient in every aspect of the business. Speeding up table turnover, taking reservations, offering customer loyalty programs, and offering a variety of payment options are all crucial to running a successful business. Focus POS offers a Mexican restaurant POS solution that offers all of these benefits, plus much more.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='axqjQPqAZOY' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image1src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Table Service POS Brochure</h2>
					<a className='widget-link white' href='https://focuspos.com/wp-content/uploads/2014/02/table_service1.pdf' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Speed of Service</h2>
						<ul>
							<li>English and Spanish language options</li>
							<li>Customized menu layouts</li>
							<li>Grouped commands</li>
							<li>High-speed credit card processing</li>
							<li>Google Wallet and Apple Pay ready</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Labor Management and Reporting</h2>
						<ul>
							<li>Inventory and sales reports</li>
							<li>Receive email or text alerts</li>
							<li>Employee scheduling</li>
							<li>Labor cost reports</li>
							<li>Schedule templates</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default MexRestPOS;