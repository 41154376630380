import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {BsXLg} from 'react-icons/bs';

const FPMYouTubeClip = forwardRef(({videoId, width, aspectX, aspectY, shadow, autoplay, onClose}, ref) => {
    const viewerStyle = {width: width};
    const contStyle = {paddingTop: `${aspectY*100/aspectX}%`};
    if (shadow)
        contStyle.boxShadow = '0 0 4px 4px rgba(0, 0, 0, 0.25)';

    const playVideo = (event, viewerRef) => {
        if (viewerRef && viewerRef.current && viewerRef.current.contentWindow) {
            console.log('Playing again');
            viewerRef.current.contentWindow.postMessage(`{"event":"command","func":"playVideo","args":""}`, '*');
        }
    }

	return(
        <div className='yt-video-viewer' style={viewerStyle}>
            {onClose && <BsXLg className='close-btn' title='Close (Esc)' onClick={(event) => {onClose(event, ref);}} />}
            {videoId && <div className='yt-video-container' style={contStyle}>
                <iframe
                    ref={ref}
                    className='yt-video'
                    src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&version=3&playerapiid=ytplayer`}
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                    onLoad={(event) => {if (autoplay) playVideo(event, ref);}}
                />
            </div>}
        </div>
	)
});

FPMYouTubeClip.propTypes = {
    videoId:  PropTypes.string,
    width:    PropTypes.string,
    aspectX:  PropTypes.number,
    aspectY:  PropTypes.number,
    shadow:   PropTypes.bool,
    autoplay: PropTypes.bool,
    onClose:  PropTypes.func,
}

FPMYouTubeClip.defaultProps = {
    videoId:  null,
    width:    '100%',
    aspectX:  16,
    aspectY:  9,
    shadow:   false,
    autoplay: false,
    onClose:  null,
}

export default FPMYouTubeClip
