import {IconContext} from 'react-icons';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMPlacard from '../components/FPM-Placard';
import FPMFeatureList from '../components/FPM-FeatureList';
import FPMImgGalery from '../components/FPM-ImgGalery';
import {FaStar} from 'react-icons/fa';
import image1src from '../images/table-image1.png';
import image2src from '../images/table-brochure-cta-background.jpg';
import glimg1src from '../images/table_burger-mods-screen.png';
import glimg2src from '../images/table_taco-salad-mods-screen.png';
import glimg3src from '../images/table_salad-screen.png';
import glimg4src from '../images/table_cart-screen.png';

const list1 = [
	{text: `The ultimate convenience of ordering from a mobile device`},
	{text: `Guests only need to enter payment information once`},
	{text: `Reduces in-person contact with staff`},
];

const list2 = [
	{text: `Boosts check sizes`},
	{text: `Cuts down on labor costs`},
	{text: `Streamlines service and kitchen operations`},
];

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'}
	];

const OrdPayTable = () => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Order and Pay at Table' />
			</div>
			<div className='text-section'>
				<h2>Order and Pay at Table with Focus POS</h2>
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700'}}>Focus Order and Pay at Table Empowers Customers to Take Control of Their Restaurant Experience</p>
						<p>Restaurant owners are always looking for new and innovative ways of engaging their customers. In an era where digital solutions are an integral part of our lives, it&rsquo;s time for restaurants to take advantage of the power of mobile devices. Whether you&rsquo;re looking to improve workflow for your staff or create a more memorable experience for your customers, Focus has the perfect mobile solution for you.</p>
						<p>Our Pay at the Table solution offers customers the ability to walk in, sit down, order, and pay at the table from the comfort and convenience of their mobile device. What sets our Order and Pay at Table apart from the rest of the competitors is that it does not close a tab until the customer selects the &quot;checkout&quot; button.</p>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row'>
				<div className='column'>
					<div className='text-section'>
						<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700'}}>How Focus Pay at Table Works:</p>
						<ol>
							<li>First, customers sit down and, using their mobile device, scan a QR code display on a table tent at their table. After scanning the code, the menu pulls up on their device.</li>
							<li>Next, customers select their items and submit their orders. They also enter the tip percentage, their name, phone number, and email address.</li>
							<li>Then, the kitchen texts the customer, letting them know that they received the order, and email them a copy of the receipt.</li>
							<li>Customers only enter their contact information once, and then they send their order to the kitchen.</li>
							<li>The tab is kept open for add-ons, such as drinks or desserts, until the customer is finally ready to hit the &quot;checkout&quot; button</li>
						</ol>
					</div>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image2src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free @Table Brochure</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/media/9799/order-and-pay-at-table-focus' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row' style={{paddingBottom: 0}}>
				<div className='column'>
					<div className='text-section'>
						<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700'}}>How You and Your Customers Can Benefit from Order and Pay at Table</p>
						<p>At Focus POS, we created our Pay at the Table solution with your customers and restaurant in mind. With Pay at Table, customers no longer have to wait to be serviced by staff: they can order their meals as soon as they’re seated. Also, because they’re in control, customers will never have to worry about getting the wrong order ever again. In addition to superior customer service, Order and Pay at Table helps free up staff to focus on other tasks and improve your work environment, keeping them organized and less prone to making unnecessary errors.</p>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0}}>
				<IconContext.Provider value={{ className: 'fg-star' }}>
					<div className='column' style={{justifyContent: 'flex-start'}}>
						<div className='text-section'>
							<FPMFeatureList
								title='Guest Benefits of Pay at Table Solution'
								titleFontSize='28px'
								listBullet={<div className='st-image-container'><FaStar className='st-image' /></div>}
								itemList={list1}
								textFontSize='18px'
								textFontWeight='700'
							/>
						</div>
					</div>
					<div className='column' style={{justifyContent: 'flex-start'}}>
						<div className='text-section'>
							<FPMFeatureList
								title='Restaurateur Benefits of Order and Pay at Table'
								titleFontSize='28px'
								listBullet={<div className='st-image-container'><FaStar className='st-image' /></div>}
								itemList={list2}
								textFontSize='18px'
								textFontWeight='700'
							/>
						</div>
					</div>
				</IconContext.Provider>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>);
}

export default OrdPayTable;