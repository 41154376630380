import {useState, useRef, useLayoutEffect} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import FPMScrollToTop from './components/FPM-ScrollToTop';
import FPMNavBar from './components/FPM-NavBar';
import FPMHeader from './components/FPM-Header.js';
import FPMFooter from './components/FPM-Footer.js';
import Home from './pages/Home.js';
import RestaurantPOS from './pages/RestaurantPOS.js';
import CloudRepMng from './pages/CloudRepMng.js';
import Solutions from './pages/Solutions.js';
import FocusPay from './pages/FocusPay.js';
import FCSubscription from './pages/FCSubscription.js';
import IPadPOS from './pages/IPadPOS.js';
import MultiCheckDB from './pages/MultiCheckDB.js';
import NFocus from './pages/NFocus.js';
import OrdPayTable from './pages/OrdPayTable.js';
import QRPay from './pages/QRPay.js';
import RestOnlineOrd from './pages/RestOnlineOrd.js';
import TabletPOS from './pages/TabletPOS.js';
import MobilePOS from './pages/MobilePOS.js';
import MyFocus from './pages/MyFocus.js';
import CloudPOS from './pages/CloudPOS.js';
import MobileMng from './pages/MobileMng.js';
import CentCloudRep from './pages/CentCloudRep.js';
import FocusOn from './pages/FocusOn.js';
import FocusKitchen from './pages/FocusKitchen.js';
import CustLoyalProg from './pages/CustLoyalProg.js';
import GiftCardProg from './pages/GiftCardProg.js';
import Industries from './pages/Industries.js';
import QuickSrvPOS from './pages/QuickSrvPOS.js';
import FastCasPOS from './pages/FastCasPOS.js';
import PickDelPOS from './pages/PickDelPOS.js';
import BarPOS from './pages/BarPOS.js';
import NightclubPOS from './pages/NightclubPOS.js';
import MexRestPOS from './pages/MexRestPOS.js';
import FoodTruckPOS from './pages/FoodTruckPOS.js';
import IceCreamPOS from './pages/IceCreamPOS.js';
import CafePOS from './pages/CafePOS.js';
import FranchisePOS from './pages/FranchisePOS.js';
import CoffeeShopPOS from './pages/CoffeeShopPOS.js';
import TableSrvPOS from './pages/TableSrvPOS.js';
import FrozYogurtPOS from './pages/FrozYogurtPOS.js';
import PizzaPOS from './pages/PizzaPOS.js';
import AboutUs from './pages/AboutUs.js';
import Partners from './pages/Partners.js';
import News from './pages/News.js';
import ContactUs from './pages/ContactUs.js';
import Support from './pages/Support.js';
import ReqDemo from './pages/ReqDemo.js';
import Resources from './pages/Resources.js';
import Videos from './pages/Videos.js';
import Cornerstone from './pages/Cornerstone.js';
import Blog from './pages/Blog.js';
import './App.css';

const contactItems = [
	{text: 'info@focusposmiami.com'},
	{text: '+1 305-508-8002'},
	];

const socialItems = [
	{href: 'https://www.facebook.com/focusposmiami'},
	{href: 'https://twitter.com/focusposmiami'},
	{href: 'https://www.linkedin.com/company/focusposmiami'},
	{href: 'https://www.youtube.com/user/focusposmiami'},
	];
	
const menus = [
	{href: '/', text: 'Home', menuItems: []},
	{href: '/restaurant-pos', text: 'Software', menuItems: [
		{href: '/restaurant-pos', text: 'Restaurant POS'},
		{href: '/solutions/restaurant-online-ordering', text: 'Restaurant Online Ordering'},
		{href: '/myfocus/cloud-reporting-and-management/', text: 'Cloud Reporting and Management'},
		{href: '/solutions/order-and-pay-at-table', text: 'Order and Pay at Table'},
		{href: '/solutions/focuspay', text: 'Payments'},
		{href: '/solutions/focus-qrpay-qr-code-payment-solution', text: 'MobilePay'},
	], defaultItemId: 0},
	{href: '/solutions', text: 'Solutions', menuItems: [
		{href: '/myfocus/customer-loyalty-program', text: 'Customer Loyalty'},
		{href: '/myfocus/gift-card-program', text: 'Gift Cards'},
		{href: '/myfocus/mobile-management', text: 'Remote Management'},
		{href: '/solutions/kitchen-display-system-pos-focuskitchen', text: 'Kitchen Display System'},
		{href: '/solutions/focuson', text: 'Mobile POS App'},
		{href: '/solutions/nfocus', text: 'Reporting Dashboard'},
	]},
	{href: '/industries', text: 'Industries', menuItems: [
		{href: '/industries/quick-service-pos', text: 'Quick Service POS'},
		{href: '/industries/fast-casual-pos', text: 'Fast Casual POS'},
		{href: '/industries/delivery-pos', text: 'Pickup and Delivery POS'},
		{href: '/industries/bar-pos', text: 'Bar POS'},
		{href: '/industries/coffee-shop-pos', text: 'Coffee Shop POS'},
		{href: '/industries/table-service-pos', text: 'Table Service POS'},
		{href: '/industries/frozen-yogurt-pos', text: 'Frozen Yogurt POS'},
		{href: '/industries/pizza-pos', text: 'Pizza POS'},
		{href: '/industries', text: 'View All Industries'},
	], defaultItemId: 8},
	{href: '/about-us/company', text: 'About', menuItems: [
		{href: '/about-us/company', text: 'Company'},
		{href: '/about-us/partners', text: 'Partners'},
		{href: '/news', text: 'News'},
	], defaultItemId: 0},
	{href: '/contact-us', text: 'Contact', menuItems: [
		{href: '/contact-us/support', text: 'Support'},
		{href: '/contact-us', text: 'Contact Us'},
	], defaultItemId: 1},
	{href: '/resources', text: 'Resources', menuItems: [
		{href: '/videos', text: 'Videos'},
		{href: '/resources', text: 'Brochures & Ebooks'},
		{href: '/resources/restaurant-management-systems-and-processes-guides', text: 'Cornerstone Content'},
	], defaultItemId: 1},
	{href: '/blog', text: 'Blog', menuItems: []},
];

function App() {
	const [selected, setSelected] = useState({menu: null, item: null});
	const [sticky, setSticky] = useState(false);
	const y = useRef(0);
	
	// Select Menu
	const selectMenuItem = (menuId, itemId) => {
		setSelected({menu: menuId, item: itemId});
	}
		
	useLayoutEffect(() => {
		function handleNavigation(e) {
      const window = e.currentTarget;
			if (y.current > window.scrollY) {
				if (window.scrollY === 0) {
					console.log('STICKY: FALSE');
					//setSticky(false);
				}
			} else if (y.current < window.scrollY) {
				if (sticky === false) {
					console.log('STICKY: TRUE');
					//setSticky(true);
				}
			}
			y.current = window.scrollY;
		}
		
		window.addEventListener('scroll', handleNavigation);
    return _ => {
      window.removeEventListener('scroll', handleNavigation);
		}
  }, [y, sticky]);
	
  return (
    <Router>
		<div className="App">
			<FPMScrollToTop />
			<Route exact path='/'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 0, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 0, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<Home onSelect={selectMenuItem} />
			</Route>
			<Route exact path='/restaurant-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 1, item: 0}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 1, item: 0}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<RestaurantPOS onSelect={selectMenuItem} />
			</Route>
			<Route exact path='/myfocus/cloud-reporting-and-management'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 1, item: 2}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 1, item: 2}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<CloudRepMng />
			</Route>
			<Route exact path='/solutions'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 2, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 2, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<Solutions onSelect={selectMenuItem} />
			</Route>
			<Route exact path='/solutions/focuspay'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 1, item: 4}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 1, item: 4}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<FocusPay />
			</Route>
			<Route exact path='/solutions/focus-as-a-subscription'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<FCSubscription />
			</Route>
			<Route exact path='/solutions/ipad-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<IPadPOS />
			</Route>
			<Route exact path='/solutions/multi-check-dashboard'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<MultiCheckDB />
			</Route>
			<Route exact path='/solutions/nfocus'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 2, item: 5}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 2, item: 5}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<NFocus />
			</Route>
			<Route exact path='/solutions/order-and-pay-at-table'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 1, item: 3}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 1, item: 3}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<OrdPayTable />
			</Route>
			<Route exact path='/solutions/focus-qrpay-qr-code-payment-solution'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 1, item: 5}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 1, item: 5}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<QRPay />
			</Route>
			<Route exact path='/solutions/restaurant-online-ordering'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 1, item: 1}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 1, item: 1}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<RestOnlineOrd />
			</Route>
			<Route exact path='/solutions/tablet-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<TabletPOS />
			</Route>
			<Route exact path='/solutions/mobile-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<MobilePOS />
			</Route>
			<Route exact path='/myfocus'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<MyFocus />
			</Route>
			<Route exact path='/myfocus/cloud-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<CloudPOS />
			</Route>
			<Route exact path='/myfocus/mobile-management'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 2, item: 2}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 2, item: 2}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<MobileMng />
			</Route>
			<Route exact path='/myfocus/central-cloud-base-reporting'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<CentCloudRep />
			</Route>
			<Route exact path='/solutions/focuson'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 2, item: 4}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 2, item: 4}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<FocusOn />
			</Route>
			<Route exact path='/solutions/kitchen-display-system-pos-focuskitchen'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 2, item: 3}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 2, item: 3}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<FocusKitchen />
			</Route>
			<Route exact path='/myfocus/customer-loyalty-program'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 2, item: 0}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 2, item: 0}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<CustLoyalProg />
			</Route>
			<Route exact path='/myfocus/gift-card-program'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 2, item: 1}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 2, item: 1}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<GiftCardProg />
			</Route>
			<Route exact path='/industries'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 3, item: 8}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 3, item: 8}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<Industries onSelect={selectMenuItem} />
			</Route>
			<Route exact path='/industries/quick-service-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 3, item: 0}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 3, item: 0}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<QuickSrvPOS />
			</Route>
			<Route exact path='/industries/fast-casual-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 3, item: 1}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 3, item: 1}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<FastCasPOS />
			</Route>
			<Route exact path='/industries/delivery-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 3, item: 2}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 3, item: 2}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<PickDelPOS />
			</Route>
			<Route exact path='/industries/bar-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 3, item: 3}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 3, item: 3}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<BarPOS />
			</Route>
			<Route exact path='/industries/nightclub-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<NightclubPOS />
			</Route>
			<Route exact path='/industries/mexican-restaurant-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<MexRestPOS />
			</Route>
			<Route exact path='/industries/food-truck-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<FoodTruckPOS />
			</Route>
			<Route exact path='/industries/ice-cream-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<IceCreamPOS />
			</Route>
			<Route exact path='/industries/cafe-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<CafePOS />
			</Route>
			<Route exact path='/industries/franchise-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<FranchisePOS />
			</Route>
			<Route exact path='/industries/coffee-shop-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 3, item: 4}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 3, item: 4}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<CoffeeShopPOS />
			</Route>
			<Route exact path='/industries/table-service-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 3, item: 5}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 3, item: 5}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<TableSrvPOS />
			</Route>
			<Route exact path='/industries/frozen-yogurt-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 3, item: 6}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 3, item: 6}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<FrozYogurtPOS />
			</Route>
			<Route exact path='/industries/pizza-pos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 3, item: 7}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 3, item: 7}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<PizzaPOS />
			</Route>
			<Route exact path='/about-us/company'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 4, item: 0}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 4, item: 0}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<AboutUs onSelect={selectMenuItem} />
			</Route>
			<Route exact path='/about-us/partners'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 4, item: 1}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 4, item: 1}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<Partners onSelect={selectMenuItem}  />
			</Route>
			<Route exact path='/news'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 4, item: 2}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 4, item: 2}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<News />
			</Route>
			<Route exact path='/contact-us'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 5, item: 1}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 5, item: 1}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<ContactUs />
			</Route>
			<Route exact path='/contact-us/support'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 5, item: 0}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 5, item: 0}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<Support />
			</Route>
			<Route exact path='/contact-us/request-a-demo'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: null, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: null, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<ReqDemo />
			</Route>
			<Route exact path='/resources'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 6, item: 1}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 6, item: 1}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<Resources />
			</Route>
			<Route exact path='/videos'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 6, item: 0}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 6, item: 0}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<Videos />
			</Route>
			<Route exact path='/resources/restaurant-management-systems-and-processes-guides'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 6, item: 2}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 6, item: 2}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<Cornerstone />
			</Route>
			<Route exact path='/blog'>
				<FPMHeader contactItems={contactItems} socialItems={socialItems} menus={menus} selected={{menu: 7, item: null}} onSelect={selectMenuItem}/>
				<FPMNavBar
					menus={menus}
					selected={{menu: 7, item: null}}
					sticky={sticky}
					onSelect={selectMenuItem}
				/>
				<Blog />
			</Route>
			<FPMFooter />
		</div>
		</Router>
  );
}

export default App;
