import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/quick-service-brochure_bg.jpg';
import image2src from '../images/data-driven-success-nfocus.png';
import glimg1src from '../images/table-service-screenshot-1024x768.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	];

const NFocus = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='nFocus' />
			</div>
			<div className='row-2'>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>nFocus: The Simple Way to Monitor Your Business</h2>
						<p>Running individual POS reports can be a thing of the past with the nFocus Dashboard. The nFocus Dashboard provides an overview of the restaurant&rsquo;s operations and has extensive drill-down capabilities that display detailed information. The nFocus Dashboard also makes it easier to operate your point of sale system with one-click icons that allow quick and convenient access to commonly used functions such as employees, time cards, and scheduling. The nFocus Dashboard lets you spend time running your business, not POS reports.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='v6SEmTZsbBQ' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image1src}>
					<h2 style={{color: '#fff'}}>4 NEW Must-Have Technologies for Your Restaurant</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/media/9799/4-new-must-have-technologies-for-your-restaurant' target='blank'>
						Download EBook
					</a>
				</FPMPlacard>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Data-Driven Success</h2>
						<p>With POS reports, you will have the insights you need to improve operational efficiency and productivity and manage labor more cost-effectively. Sales reports, whether daily, quarterly, or annual, let you monitor performance at each location. You can also compare annual reports to gauge business growth or to identify seasonal trends. Inventory reports can help ensure you don&rsquo;t run out of necessary ingredients and order the right quantities so it doesn&rsquo;t result in waste. Labor reports can show if a restaurant is staffed optimally and how individual employees are performing. Without insights POS reports, you may be managing based on intuition, not fact. Backing up decisions with data is a smarter strategy.</p>
					</div>
				</div>
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>nFocus POS Reports</h2>
						<p>All the data you need to make smart business decisions is at your fingertips with the nFocus Dashboard. Access it for high level data or drill down for a more detailed look at specific areas of your business.</p>
					</div>
				</div>
			</div>
			<div className='row-3' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section' style={{color: '#f4a520'}}>
						<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', margin: 0}}>Summary Data</p>
						<ul>
							<li>Net sales</li>
							<li>Labor costs</li>
							<li>Check averages</li>
							<li>Sales by period</li>
							<li>Tracking items</li>
							<li>Sales by order type</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section' style={{color: '#f4a520'}}>
						<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', margin: 0}}>Labor Information</p>
						<ul>
							<li>Overtime warnings</li>
							<li>Individual warnings</li>
							<li>Employees on the clock</li>
							<li>Employees by job</li>
							<li>Individual employees</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section' style={{color: '#f4a520'}}>
						<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', margin: 0}}>Systems Alerts</p>
						<ul>
							<li>Current date</li>
							<li>Business date</li>
							<li>Last batch information</li>
							<li>Printer status</li>
							<li>Credit card status</li>
							<li>VIP pricing status</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default NFocus;