import {Link} from 'react-router-dom';

const FPMStackMenuBody = ({id, expanded, menuItems, selected, onClose, onSelect}) => {
	let classStr = 'stack-menu-body';
	if (id === expanded)
		classStr += ' stack-menu-body-expanded';
	return(
			<div className={classStr}>
			{
				menuItems.map((item, index) => {
					return(
						
							<Link key={index} className={selected.menu != null && id === selected.menu && selected.item != null && index === selected.item ? 'selected' : null}
								to={item.href}
								onClick={() => {onClose();onSelect(id, index);}}>
								{item.text}
							</Link>);
				})
			}
			</div>

	)
}

export default FPMStackMenuBody
