import {useState, useRef, useEffect} from 'react';
import {FaAngleLeft} from 'react-icons/fa';
import {FaAngleRight} from 'react-icons/fa';
import FPMImgViewerOverlay from './FPM-ImgViewerOverlay';

const FPMImgGalery = ({imgList}) => {
	const imgCount = imgList.length;
	const imgMinWidth = 250;
	const visibleImgMin = 0;
	const visibleImgMax = useRef(0);
	const galeryRef = useRef(null);
	const galeryWindowWidth = useRef(null);
	const leftBtnRef = useRef(null);
	const rightBtnRef = useRef(null);
	const [galeryAttribs, setGaleryAttribs] = useState({imgWidth: imgMinWidth, firstImgIdx: 0, transition: '0s'});
	const visibleImgCount = useRef(0);
	const [btnDisabled, setBtnDisabled] = useState([false, false]);
	const scrolling = useRef(false);

	const [viewerAttribs, setViewerAttribs] = useState({active: false, image: null, index: 0});
	//const viewerRef = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			//setImgTransition('0s');
			console.log('UPDATING-UPDATING-UPDATING-UPDATING-UPDATING-UPDATING');
			if (galeryRef && galeryRef.current) {
				galeryWindowWidth.current = galeryRef.current.clientWidth;
				visibleImgCount.current = parseInt(galeryWindowWidth.current / imgMinWidth);
				visibleImgMax.current = visibleImgCount.current > imgCount ? 0 : imgCount - visibleImgCount.current;
				setGaleryAttribs({
					imgWidth: galeryWindowWidth.current / visibleImgCount.current,
					firstImgIdx: Math.min(galeryAttribs.firstImgIdx, visibleImgMax.current),
					transition: (scrolling.current === true) ? '.5s' : '0s',
				});
				scrolling.current = false;
			}
		}
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [galeryAttribs.firstImgIdx, imgCount]);

	const clickLeft = () => {
        setBtnDisabled([true, btnDisabled[1]]);
		if (galeryRef && galeryRef.current) {
			if (galeryAttribs.firstImgIdx > visibleImgMin) {
				//setImgTransition('.5s');
				scrolling.current = true;
				setGaleryAttribs({
					imgWidth: galeryAttribs.imgWidth,
					firstImgIdx: Math.max(galeryAttribs.firstImgIdx - visibleImgCount.current, visibleImgMin),
					transition: '.5s',
				});
			}
		}	
		setTimeout(() => {setBtnDisabled([false, false]);}, 600);
    }

	const clickRight = () => {
		setBtnDisabled([btnDisabled[0], true]);
        if (galeryRef && galeryRef.current) {
			if (galeryAttribs.firstImgIdx < visibleImgMax.current) {
				//setImgTransition('.5s');
				scrolling.current = true;
				setGaleryAttribs({
					imgWidth: galeryAttribs.imgWidth,
					firstImgIdx: Math.min(galeryAttribs.firstImgIdx + visibleImgCount.current, visibleImgMax.current),
					transition: '.5s',
				});
			}
		}
		setTimeout(() => {setBtnDisabled([false, false]);}, 600);
    }

	const galeryScroll = () => {
		
	}

	const openViewer = (event, image, index) => {
		//event.preventDefault();
		setViewerAttribs({
			active: true,
			image: image,
			index: index,
		});
	}

	const closeViewer = () => {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
		setViewerAttribs({
			active: false,
			image: null,
			index: 0,
		});
    }

	return(
		<>
		<div className='img-galery-container'>
			<div ref={leftBtnRef} className='btn left' onClick={btnDisabled[0] ? null : clickLeft}><FaAngleLeft style={{fontSize: '1.5em', color: 'white'}}/></div>
			<div className='img-galery-auxcontainer'>
			<div className='img-galery' ref={galeryRef} onScroll={galeryScroll} style={{transition: 'left ' + galeryAttribs.transition, left: ((visibleImgMin - galeryAttribs.firstImgIdx) * galeryAttribs.imgWidth) + 'px'}}>
				{
					imgList.map((item, index) => {
						return(
							<img key={index} className='gl-grid-item' src={item.src} alt={item.alt} style={{minWidth: galeryAttribs.imgWidth + 'px', maxWidth: galeryAttribs.imgWidth + 'px'}} onClick={(event) => {openViewer(event, item, index);}}/>
						);
					})
				}
			</div>
			</div>
			<div ref={rightBtnRef} className='btn right' onClick={btnDisabled[1] ? null : clickRight}><FaAngleRight style={{fontSize: '1.5em', color: 'white'}} /></div>
		</div>
		<FPMImgViewerOverlay 
			active={viewerAttribs.active}
			image={viewerAttribs.image}
			index={viewerAttribs.index}
			onClose={closeViewer}
		/>
		</>
	)
}

export default FPMImgGalery
