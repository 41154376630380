import FPMPageTitle from '../components/FPM-PageTitle';
import FPMPlacard from '../components/FPM-Placard';
import image1src from '../images/focusKitchen1.png';
import image2src from '../images/mobile-pos-solution-brochure_bg.jpg';
import image3src from '../images/focusKitchen2.png';
import image4src from '../images/focusKitchen3.png';

const FocusKitchen = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='FocusKITCHEN Cloud-Based Kitchen Display System' />
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Streamline Order Prep from Online, In-House, and Drive-Thru</h2>
						<p>Welcome your kitchen into the 21st century with our latest Cloud-based kitchen video system: FocusKITCHEN. Our newest kitchen display system POS can help automate your kitchen, expedite order prep, and streamline processes with efficiency and ease. Plus, FocusKITCHEN provides you the ability to drop orders from online, in-house, and drive-thru into the order queue.</p>
						<p>When you use our kitchen display system POS, you&rsquo;ll see a difference in productivity and profitability for your business. Modern problems require modern solutions, and at Focus POS, we&rsquo;ll take on your toughest challenges. Our technology can help you worry less and concentrate more on how to continue growing your business.</p>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image2src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free FocusKITCHEN Brochure</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/fs/blocks/showLandingPage/a/9799/p/p-0087/t/page/fm/0' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Automate Your Kitchen, Reduce Paper Waste, and Deliver Exceptional Service</h2>
						<p>As a Cloud-based kitchen display system POS, FocusKITCHEN can have your business operations perform better and reduce errors simultaneously. It&rsquo;s a system built around saving you time and money.</p>
						<p>Speaking of saving money, FocusKITCHEN reduces errors in recall receipts, increasing organization and minimizing your paper waste. It&rsquo;s a great way of making your kitchen more eco-friendly while keeping extra cash in your pocket.</p>
						<p>FocusKITCHEN is a win-win when it comes to delivering exceptional service and keeping your kitchen running smoothly.</p>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>FocusKITCHEN Features</h2>
						<ul>
							<li>Bump individual items</li>
							<li>Check recall</li>
							<li>Dynamic item routing</li>
							<li>Decrease paper waste</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>);
}

export default FocusKitchen;