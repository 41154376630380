import FPMPageTitle from '../components/FPM-PageTitle';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/waitress-using-tablet-pos.png';
import image2src from '../images/tablet-pos-solution-brochure_bg.jpg';
import image3src from '../images/waitress-taking-orders-tablet-pos.png';
import glimg1src from '../images/galaxy_tablet-pos-software-mockup1-1024x700.png';
import glimg2src from '../images/galaxy_tablet-pos-software-mockup2-1024x700.png';
import glimg3src from '../images/galaxy_tablet-pos-software-mockup3-1024x700.png';
import glimg4src from '../images/galaxy_tablet-pos-software-mockup4-1024x700.png';
import glimg5src from '../images/galaxy_tablet-pos-software-mockup5-1024x700.png';
import glimg6src from '../images/galaxy_tablet-pos-software-mockup6-1024x700.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
    {src: glimg6src, alt: 'glimg6'},
	];

const TabletPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Tablet POS' />
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Stay Flexible & Optimize Your Restaurant’s Functionality with POS for Tablets</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>As a powerful extension to the traditional Focus POS system, our Tablet POS offers the versatility you need to grow your business. Building upon the robust features and functionalities that drive efficiency and productivity, our POS for tablets is a proven revenue-boosting tool for your restaurant. Your tablet can function as a stationary point of sale (POS), or you can utilize it to process tableside orders, pay-at-the-table, line-busting applications, and more. No matter the circumstance, our Tablet POS is always ready to help your restaurant adapt and grow.</p>
						<p>Also, our Focus tablet point of sale solution is the perfect tool to help empower your wait staff with features that streamline workflows and deliver on customer expectations. For example, a customizable menu layout and packaged commands allow your team to service customers quickly and accurately while providing an enhanced customer experience. Additionally, pop-up notifications encourage the upselling of menu items—like appetizers and desserts—with the highest profit margin potential.</p>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image2src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Tablet POS Brochure</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/media/9799/download-tablet-pos-brochure' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
            <div className='row' style={{paddingBottom: 0, backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Take Advantage of Our Tablet POS and Its Powerful Range of Features</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>Time is of the essence when it comes to surviving the restaurant industry. So, the last thing your business needs is costly training expenses before you implement a solution. Our POS for tablets can guarantee that you won’t lose time or money due to staff training since the software uses the same interface as the traditional Focus POS system.</p>
						<p>You can increase productivity by streamlining the ordering process and getting tickets to the kitchen more quickly. In addition, our Tablet POS helps your wait staff improve order accuracy, suggest add-on menu items, and boost customer satisfaction. Some of our other tablet point of sale features include:</p>
						<ul>
							<li>Seamless integration with our standard Focus POS system</li>
							<li>Minimum time required for employee training</li>
							<li>Customize your menu layout for optimal efficiency</li>
							<li>Service customers quickly and effectively with packaged command entry</li>
							<li>Creating prompts to remind your staff to suggest add-on menu items</li>
							<li>High-speed credit card processing</li>
							<li>Inventory and sales reporting and data</li>
                            <li>Efficient employee time-tracking and reusable schedules</li>
							<li>Email and text message alerts for better business visibility</li>
						</ul>
					</div>
				</div>
			</div>
            <div className='row' style={{backgroundColor: '#fff'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
            <div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default TabletPOS;