import FPMPageTitle from '../components/FPM-PageTitle';
import image1src from '../images/FocusPay-500x175.jpeg';
import image2src from '../images/focuson-screen-768x633-1-500x412.png';
import image3src from '../images/card-icon.png';
import image4src from '../images/icon_help.png';
import image5src from '../images/analytics-1-100x100-1.png';

const FocusPay = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='FocusPay' />
			</div>
			<div className='row'>
				<div className='column'>
					<img src={image1src} alt='image1' style={{maxWidth: '500px'}}/>
					<div className='text-section' style={{maxWidth: '720px'}}>
						<p>It couldn&rsquo;t be easier for your restaurant or bar to accept credit cards with FocusPay. Whether you use it as a Payments Acquirer or Gateway, FocusPay can incorporate itself into your current POS system so you can safely process EMV transactions or swipe.</p>
					</div>
					<img src={image2src} alt='image2' style={{maxWidth: '500px'}}/>
					<div className='text-section' style={{maxWidth: '720px'}}>
						<h1>FocusPay</h1>
						<p>It couldn&rsquo;t be easier for your restaurant or bar to accept credit cards with FocusPay. Whether you use it as a Payments Acquirer or Gateway, FocusPay can incorporate itself into your current POS system so you can safely process EMV transactions or swipe.</p>
						<h3>Payments Acquirer</h3>
						<p>Focus Pay works with your Focus system to accept payments from card brands such as Visa, Mastercard, Discover, and American Express. It allows for a wholly integrated and maintained electronic payment system.</p>
						<h3>Payments Gateway</h3>
						<p>FocusPay empowers you to choose and use your credit card processor, sales organization, or merchant service processor while accepting electronic payments instantly through your Focus system.</p>
					</div>
				</div>
			</div>
			<div className='row-3' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<img src={image3src} alt='image3' style={{width: '100px', margin: '1em 0'}}/>
					<div className='text-section' style={{color: '#f4a520'}}>
						<p>Payments Acquirer</p>
					</div>
				</div>
				<div className='column'>
					<img src={image4src} alt='image4' style={{width: '100px', margin: '1em 0'}}/>
					<div className='text-section' style={{color: '#f4a520'}}>
						<p>Outstanding Technical Support</p>
					</div>
				</div>
				<div className='column'>
					<img src={image5src} alt='image5' style={{width: '100px', margin: '1em 0'}}/>
					<div className='text-section' style={{color: '#f4a520'}}>
						<p>Acquire Customer Analytics Information</p>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Have Questions About FocusPay?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/contact-us/' target='blank'>
						Contact Us
					</a>
				</div>
			</div>
		</>);
}

export default FocusPay;