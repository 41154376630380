import {FaAngleRight} from 'react-icons/fa';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMFeatureList from '../components/FPM-FeatureList';
import image1src from '../images/fast-casual-1.png';
import image2src from '../images/fast-casual-3.png';
import image3src from '../images/fast-casual-2.png';
import image4src from '../images/board-brew.jpg';
import image5src from '../images/download.png';
import image6src from '../images/23651626-0-DogHaus-Logo-Primary.png';
import image7src from '../images/1200x630wa.png';
import image8src from '../images/people-at-the-pub-bar-pos.png';
import image9src from '../images/mockup_4-new-must-have-technologies-for-your-restaurant.png';
import image10src from '../images/mockup_5-ways-tablet-pos-increases-restaurant-revenue.png';
import image11src from '../images/mockup_calculate-restaurant-roi-from-pos.png';
import image12src from '../images/Focus_future-proof-your-restaurant_book2.png';

const list1 = [
	{text: `Customer-facing displays that support suggestive selling, tip prompts, and sign-on screen for credit cards`},
	{text: `Custom menus and screens to speed up order entries`},
	{text: `The flexibility to accept multiple payment options for your customers’ convenience`},
	];

const list2 = [
	{text: `Seamless combo functions for multiple item entry`},
	{text: `Integration with kitchen video systems that improve accuracy and reduce preparation time`},
	{text: `A user-friendly interface to help streamline the training of employees`},
	{text: `Managing multiple revenue centers at once to keep workers organized and on track`},
	];

const list3 = [
	{text: `Robust reporting and auditing to accurately manage shrinkage and track inventory`},
	{text: `Analyzing your sales to determine a long-term strategy`},
	{text: `Tracking and breaking down your profits from each revenue center to reveal trends and gain greater visibility into your operations`},
	{text: `Gaining valuable insight into customer behavior so you can leverage and build customer loyalty`},
	];

const list4 = [
	{text: `Multi-Check Dashboard from Focus POS helps your workers stay organized by effortlessly managing several checks on one screen, eliminating any chance for error`},
	{text: `Online Ordering allows your customers to avoid long lines through pre-ordering from their device. Plus, they can take your restaurant with them wherever and whenever they go`},
	{text: `With Order and Pay at Table, customers scan a QR code display with their mobile device, pull up your menu, and order and pay right from their phone. It’s that simple!`},
	];

const list5 = [
	{text: `Swift and efficient retrieval to your onsite services and sales`},
	{text: `The ability to pick your partners so you can manage and control credit card fees`},
	{text: `Software you know you can depend on`},
	];

const FastCasPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Fast Casual POS' />
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Serve Customers Within the Blink-of-an-Eye With Fast Casual POS</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>Consumer demand for fresh, high-quality food served quickly and at an affordable price has helped fuel the popularity of fast-casual restaurants. Meeting this demand is more manageable with software for fast casual restaurants from Focus POS, which helps your business maximize efficiency, productivity, and profitability.</p>
						<p>You need a fast casual POS system with the right features at the right price. With Focus POS, your business can streamline workflows, speed up order times, and maximize the performance of your kitchen to meet customer expectations. In addition, you can optimize your back office management with robust inventory tracking and smart reporting to give you that touch-of-a-button insight into business operations.</p>
						<p>And with solutions, such as Restaurant Online Ordering and Order and Pay at Table, your fast casual restaurant software can help you entice customers with new ways of engaging your establishment.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='iG9kBRdvT64' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Fast Service = One Satisfied Customer'
							description='The last thing your customers want to do is wait for orders while their stomachs are grumbling. Keep your customers and their stomachs satisfied with a fast casual POS system from Focus POS. Our point of sale has features, such as:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list1}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Help Your Staff Streamline Restaurant Operations'
							description='Your employees are the backbone of your restaurant operations’ success. With our software for fast casual restaurants, you can improve your staff’s workflow and keep business running smoothly. Our software features capabilities like:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list2}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Utilize the Power of Back-End Reporting and Key Insights'
							description='With robust, back-end reporting, your fast casual restaurant software can allow you to have unlimited visibility over your operations—and the ability to make decisions centered around crucial insights to help you grow your revenue. Notable features include:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list3}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Boost Your Fast Casual POS Restaurant Management System'
							description='As a bonus to our Focus POS Restaurant Management Software basics, you can upgrade customer experience with our other feature-rich solutions.'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list4}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image2src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>Customers</h2></div>
						<div className='wall-item'><img className='exp-img' src={image4src} alt='image5' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image6' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image6src} alt='image7' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image7src} alt='image8' style={{maxWidth: '250px'}}/></div>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Why Choose Focus POS Software for Fast Casual Restaurants?'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list5}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image8src} alt='image9'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>E-Books</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image9src} alt='image10' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>4 NEW Must-Have Technologies for Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/4-new-must-have-technologies-for-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image10src} alt='image11' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>5 Ways Tablet POS Increases Restaurant Revenue</p>
							<a className='widget-link' href='https://www.focuspos.com/5-ways-tablet-pos-increases-restaurant-revenue/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image11src} alt='image12' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>How to Calculate Your Restaurant’s ROI from a POS Deployment</p>
							<a className='widget-link' href='https://www.focuspos.com/how-to-calculate-your-restaurants-roi-from-a-pos-deployment/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image13' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Ways to Future-Proof Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/6-ways-to-future-proof-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FastCasPOS;