import {FaAngleRight} from 'react-icons/fa';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMFeatureList from '../components/FPM-FeatureList';
import image1src from '../images/female-bartender-bar-pos.png';
import image2src from '../images/bar3.png';
import image3src from '../images/bar4.png';
import image4src from '../images/people-at-the-pub-bar-pos.png';
import image5src from '../images/AJLogo.png';
import image6src from '../images/23651626-0-DogHaus-Logo-Primary.png';
import image7src from '../images/topgolf-international-inc-logo-vector.png';
import image8src from '../images/TSR-Logo-Updated.png';
import image9src from '../images/bar2.png';
import image10src from '../images/mockup_4-new-must-have-technologies-for-your-restaurant.png';
import image11src from '../images/mockup_5-ways-tablet-pos-increases-restaurant-revenue.png';
import image12src from '../images/mockup_calculate-restaurant-roi-from-pos.png';
import image13src from '../images/Focus_future-proof-your-restaurant_book2.png';

const list1 = [
	{text: `Multitask by having unlimited tabs visible at one time`},
	{text: `Comply with regulations with integrated age verification`},
	{text: `Enter different pricing tiers for your most important customers`},
	{text: `Never forget how to make a drink with drink recipe lookup`},
	{text: `Keep track of timed rates for extras like pool table usage`},
	];

const list2 = [
	{text: `Control bar POS access with fingerprint recognition`},
	{text: `Place multiple cash drawers on a station`},
	{text: `Double drink pricing`},
	{text: `Customize screens for faster bar service`},
	{text: `Streamline employee training with user-friendly interface`},
	];

const list3 = [
	{text: `Implement liquor inventory integration so you never over or under-order`},
	{text: `Accurately manage shrinkage with robust reporting and auditing`},
	{text: `Analyze your sales to determine a long-term strategy`},
	];

const list4 = [
	{text: `Focus Loyalty — to keep customers coming back for more`},
	{text: `Focus gift cards — to supplement revenue`},
	{text: `Order and pay at the table — to improve table turnover`},
	];

const BarPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Bar POS' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>A Bar POS that Keeps the Tap Flowing</h2>
						<p>At Focus POS, we understand the needs of the bar and nightclub industry. For an industry that relies heavily on providing a seamless customer experience to meet nightlife demands, your business needs a solution that allows staff to spend less time in front of a screen, and more time pouring drinks and providing top-notch service.</p>
						<p>Our bar POS system allows bartenders to manage multiple tabs at once and easily repeat rounds with a single touch to keep drinks flowing. Intuitive bar software allows tabs to be easily opened and closed in seconds by simply swiping a customer’s card or entering their name. By equipping your business with our POS solution, you’ll find that your bartenders never lose a second — or a tip.</p>
					</div>
					<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Bar-Service-Brochure_web.pdf' target='blank' style={{minWidth: '300px'}}>
						Learn more with our Bar POS Brochure
					</a>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='7_vYfLVLPj8' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Provide Superior Customer Service'
							description='No matter how busy your bar or nightclub is, it’s your job to ensure that every patron is served quickly and accurately — and keeps coming back for refills instead of opting for a faster service watering hole. Our bar POS allows you to do just that:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list1}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Manage your Bartenders'
							description='Assure that your customers remember your high-quality beverages and excellent service by equipping your bartenders with the best bar software around. Our bar POS solution allows you to:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list2}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Analyze and Optimize with POS Software for Bars'
							description='With feature-rich, back-end reporting, your bar software allows you to have complete visibility over your operations — and the ability to make the best decisions to grow your revenue. Our bar POS will enable you to:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list3}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Enhance Your Bar Software: Bar POS Add-Ons'
							description='In addition to Focus POS Restaurant Management Software basics, you can provide an even fuller experience for your customers by adding enhanced solutions such as:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list4}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>Customers</h2></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image5' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image6src} alt='image6' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image7src} alt='image7' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image8src} alt='image8' style={{maxWidth: '250px'}}/></div>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Why Choose Focus POS for Your Bar Software?</h2>
						<p>In such a bustling industry, your bar POS should possess capabilities to easily manage every aspect of your business, so that you and your staff can spend more time offering excellent service. Focus’s highly reliable bar software will give you speedy, simple access to onsite sales and service, as well as the freedom to process with your own partners, keeping credit card fees to a minimum. What are you waiting for? Enhance performance and reduce costs in your bar or nightclub with Focus bar POS and software.</p>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image9src} alt='image9'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>E-Books</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image10src} alt='image10' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>4 NEW Must-Have Technologies for Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/4-new-must-have-technologies-for-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image11src} alt='image11' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>5 Ways Tablet POS Increases Restaurant Revenue</p>
							<a className='widget-link' href='https://www.focuspos.com/5-ways-tablet-pos-increases-restaurant-revenue/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image12' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>How to Calculate Your Restaurant’s ROI from a POS Deployment</p>
							<a className='widget-link' href='https://www.focuspos.com/how-to-calculate-your-restaurants-roi-from-a-pos-deployment/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image13src} alt='image13' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Ways to Future-Proof Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/6-ways-to-future-proof-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BarPOS;