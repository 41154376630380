import {useState, useEffect} from 'react';
import FPMToggleMenuBtn from './FPM-ToggleMenuBtn';
import FPMRightMenuList from './FPM-RightMenuList';
import FPMAppOverlay from './FPM-AppOverlay';

const FPMSlideMenu = ({menus, selected, onSelect}) => {
	const [active, setActive] = useState(false);
	
	useEffect(() => {
    function handleResize() {
      if (active && window.innerWidth >= 992)
				setActive(false);
		}
		window.addEventListener('resize', handleResize);
    return _ => {
      window.removeEventListener('resize', handleResize);
		}
	})
	
	const openSlideMenu = () => {
		setActive(true);
	}
	
	const closeSlideMenu = () => {
		setActive(false);
	}
	
	const toggleSlideMenu = () => {
		setActive(!active);
	}
	
	return(
		<div className='slide-menu'>
			<FPMToggleMenuBtn
				onToggle={toggleSlideMenu}
			/>
			<FPMRightMenuList 
				active={active}
				menus={menus} 
				selected={selected}
				onSelect={onSelect}
				onClose={closeSlideMenu}
			/>
			<FPMAppOverlay 
				active={active}
				onClose={closeSlideMenu}
			/>
		</div>
	);
}

export default FPMSlideMenu;
