import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/mobile-pos-solution-brochure_bg.jpg';
import image2src from '../images/focus-mobile-pos-solution.png';
import image3src from '../images/paying-bill-focus-mobile-pos-features.png';
import glimg1src from '../images/Mobile-01-577x1024.png';
import glimg2src from '../images/Mobile-02-577x1024.png';
import glimg3src from '../images/Mobile-03-577x1024.png';
import glimg4src from '../images/Mobile-04-577x1024.png';
import glimg5src from '../images/Mobile-05-577x1024.png';
import glimg6src from '../images/Mobile-06-577x1024.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
    {src: glimg6src, alt: 'glimg6'},
	];

const MobilePOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Mobile POS' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Maximum Mobility Using a Smartphone or Tablet Device</h2>
						<p>Streamline service with mobile point of sale (POS) to give your customers a convenient and memorable experience while eliminating confusion and wait times during peak hours. With Focus mobile POS solutions on smartphones or tablets, you can take orders, line bust during peak times, and make last-minute adjustments to orders—like adding an appetizer or dessert—that will help increase your sales and profit margin.</p>
                        <p>Focus mobile POS feature the robust functionality and rock-solid reliability that has made Focus POS and industry leader. Our mobile solutions help you enhance your business operations both inside and outside your restaurant—on food trucks, in outdoor dining areas, or open air events—wherever you take your mobile device. By bringing Focus mobile POS to where your customers are, you can increase your restaurant’s productivity and profits.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='cf3pF69dCUQ' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image1src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free MyFocus Mobile Brochure</h2>
					<a className='widget-link white' href='https://focuspos.com/wp-content/uploads/2014/02/MyFocus-Mobile_final.pdf' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
            <div className='row-2'>
                <div className='column'>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
                <div className='column'>
					<div className='text-section'>
						<h2>Increase Service Speed and Sales, Decrease Training</h2>
						<p>With Focus mobile POS, your staff is free from the constraints of a stationary point of sale, so they can quickly service your customers and spend more time providing a memorable customer experience. Focus mobile POS features custom menu layouts and packaged commands so your wait staff can work more efficiently, and sale suggestions so they can be more effective at upselling and increasing average order size. Focus mobile POS also supports high-speed credit card processing for quick, hassle-free transactions.</p>
					</div>
				</div>
			</div>
            <div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
            <div className='row-2'>
                <div className='column'>
					<div className='text-section'>
						<h2>Focus Mobile POS Features</h2>
						<ul>
							<li>Easy for your employees to learn with virtually no training required</li>
							<li>Compatible with both tablets and smartphones</li>
							<li>Notifications for your staff to upsell high-margin items</li>
							<li>Intuitive navigation to take orders quickly</li>
							<li>Easy touch-screen entering of menu modifiers</li>
							<li>Quickly split and close checks</li>
							<li>Inventory and sales reports</li>
                            <li>Labor scheduling</li>
						</ul>
					</div>
				</div>
                <div className='column'>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
			</div>
            <div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default MobilePOS;