import FPMPageTitle from '../components/FPM-PageTitle';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/happy-restaurant-owner-faas.png';
import image2src from '../images/focus-as-a-subscription-solution_bg.jpg';
import image3src from '../images/handing-coupon-faas.png';
import glimg1src from '../images/table-service-screenshot-1024x768.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	];

const FCSubscription = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Focus as a Subscription' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>All the Advantages, Less Upfront Cost and Lower Risk</h2>
						<p>A state-of-the-art point of sale (POS) system provides your restaurant with features and functionality that help you operate profitably and competitively, but a POS solution can be a significant expenditure for your restaurant. Focus as a Subscription (FaaS) gives you an alternative to a traditional POS purchase by providing a top-of-the-line POS system at a low-cost point of entry.</p>
                        <p>With FaaS, you pay for Focus software on a monthly, subscription basis, paying as you go. As long as you are a subscriber, you have access to unlimited, free upgrades and the ability to scale. In addition, you will mitigate risk with the flexibility to adjust your POS system to meet the demands of a rapidly changing industry. FaaS allows you to quickly add components to your POS system as your business changes and grows. If you need additional point of sale stations for a special event, FaaS allows you to add terminals to handle increased traffic. Focus as a Subscription lets you decide what your point of sale system needs to be, today and tomorrow.</p>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image2src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Focus as a Subscription Brochure</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/media/9799/focus-as-a-subscription-brochure' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-3'>
						<div className='wall-title'>
							<h2>Same Great Focus POS Software, on a Subscription Basis</h2>
							<p>Focus as a Subscription equips your business with the same great features as Focus POS purchased through a traditional license.</p>
						</div>
						<div className='wall-item'>
							<div className='text-section'>
								<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', marginBottom: '.25em'}}>Table Management</p>
								<p>FaaS provides your restaurant with interactive, customizable, graphic floor plans, table status and times, pool table timer.</p>
							</div>
						</div>
						<div className='wall-item'>
							<div className='text-section'>
								<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', marginBottom: '.25em'}}>Order Processing</p>
								<p>Provide fast, efficient customer service with touchscreen order entry. You can also easily split checks, open tabs, and manage transfers, voids and comps. FaaS also allows you to accept your customers’ preferred payments types and gift cards.</p>
							</div>
						</div>
						<div className='wall-item'>
							<div className='text-section'>
								<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', marginBottom: '.25em'}}>Detailed Reporting</p>
								<p>Keep a close watch on your business with labor, inventory, and sales reports, and leverage data for theft prevention. You can also analyze data for KPIs such as table turns and employee performance, and easily manage sales contests. FaaS also enables an export to QuickBooks.</p>
							</div>
						</div>
						<div className='wall-item'>
							<div className='text-section'>
								<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', marginBottom: '.25em'}}>Enhanced Security</p>
								<p>Focus as a Subscription can be integrated with your security camera system, provide you with real-time alerts, control access with fingerprint ID, and support age verification for liquor sales. FaaS also support secure payment processing.</p>
							</div>
						</div>
						<div className='wall-item'>
							<div className='text-section'>
								<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', marginBottom: '.25em'}}>Inventory Control</p>
								<p>Manage inventory, control costs, calculate menu item costs and access usage reports.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
            <div className='row-2'>
                <div className='column'>
					<div className='text-section'>
						<h2>Focus as a Subscription Optional Modules</h2>
						<p>You can expand the capabilities of your Focus as a Subscription solution by adding optional modules for an additional cost:</p>
						<ul>
							<li>Labor scheduling</li>
							<li>Hotel interface</li>
							<li>Customer service/delivery</li>
							<li>Alerts</li>
							<li>Gift cards</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
			</div>
            <div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default FCSubscription;