import {Link} from 'react-router-dom';
import FPMPageTitle from '../components/FPM-PageTitle';
import image1src from '../images/happy-restaurant-owners-company-page.png';
import image2src from '../images/smiling-restaurant-workers-company-page.png';

const AboutUs = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Company' />
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>We Are Committed to One Goal: Great Restaurant Point of Sale</h2>
						<p>For nearly 30 years, Focus POS Systems has provided restaurant point of sale software that helps businesses run successfully. Since 1990, we have aimed to give restaurants a competitive edge by providing simple, yet powerful restaurant management solutions, designed specifically for the industry. With Focus POS, you won’t have features you never use—and you will have all the features your restaurant needs. Our reliable products combined with our tried-and-true methodology deliver business value with efficient operations, increased productivity, and greater profitability.</p>
					</div>
					<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
				<div className='column'>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row-2-1' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column-1'>
					<img className='exp-img' src={image2src} alt='image2' style={{maxWidth: '400px'}} />
				</div>
				<div className='column-2'>
					<div className='text-section'>
						<h2>We Love to Share What We Know About the Industry</h2>
						<p>We’ve been at the forefront of the restaurant point of sale industry for decades, and we understand what restaurants need from technology solutions, what works best—and what doesn’t. Our clients don’t have to start from square one to find the right solutions. You can leverage our experience and be confident that the technology investment you are making will deliver the results you are looking for.</p>
						<p>Focus POS is provided through a knowledgeable, skilled network of partners, and your local Focus POS dealer is ready to speak with you one-on-one to understand your business, your challenges and your goals. And if you need information on industry trends, the latest technology advancements, or how to use a solution to overcome a business challenge, you’ve just found the way to connect to a valuable resource.</p>
					</div>
					<Link className='widget-link' to='/contact-us' onClick={() => onSelect(5, 1)}>
						Find a Reseller
					</Link>
				</div>
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					
						<h2>We Celebrate Our Customers’ Success</h2>
						<p style={{textAlign: 'center'}}>Focus POS is installed at more than 15,000 unique locations worldwide, including table and counter service, take-out, delivery, bars, nightclubs and cafes. Many well-known hospitality organizations rely on Focus POS to streamline their daily operations, including TopGolf, Second City Comedy Club, Smoothie King, Billy Sim’s BBQ, Johnnie’s New York Pizza, Popeye’s, Arby’s, Cold Stone Creamery, and many more!</p>
					
				</div>
			</div>
		</>);
}

export default AboutUs;