import {Link} from 'react-router-dom';
import {IconContext} from 'react-icons';
import {FaArrowRight} from 'react-icons/fa'
import FPMImageSlideShow from '../components/FPM-ImageSlideShow';
import FPMPlacard from '../components/FPM-Placard';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMFeatureList from '../components/FPM-FeatureList';
import image1src from '../images/louis3.jpg';
import image2src from '../images/louis4.jpg';
import image3src from '../images/louis5.jpg';
import image4src from '../images/louis6.jpg';
import image5src from '../images/restaurant-pos-buyers-guide_bg.jpg';
import image6src from '../images/product-benefits-ipad-mockup.png';
import image7src from '../images/world-globe-background.png';
import image8src from '../images/installations-background.png';
import image9src from '../images/terminals-background.png';
import image10src from '../images/transactions-background.png';
import image11src from '../images/national-resellers-background.png';
import image12src from '../images/solution_restaurant-pos.jpg';
import image13src from '../images/solution_mobile-pos_2.jpg';
import image14src from '../images/solution_ipad-pos_2.jpg';
import image15src from '../images/solution_multi-check-dashboard_2.jpg';
import image16src from '../images/solution_restaurant-online-ordering.jpg';
import image17src from '../images/solution_nfocus_2.jpg';
import image18src from '../images/solution_faas.jpg';
import image19src from '../images/solution_tablet-pos_2.jpg';
import image20src from '../images/industry_table-service-250x225.jpg';
import image21src from '../images/industry_quick-service-250x225.jpg';
import image22src from '../images/industry_fast-casual-250x225.jpg';
import image23src from '../images/industry_bar-pos-250x225.jpg';
import image24src from '../images/industry_cafe-pos-250x225-250x225.jpg';
import image25src from '../images/industry_delivery-250x225-250x225.jpg';
import image26src from '../images/industry_frozen-yogurt-250x225-250x225.jpg';

const list1 = [
	{feature: `Easy to Use`, text: `Our intuitive touchscreen interface minimizes the time and cost necessary to train new employees and gives your experienced staff the ability to work more productively.`},
	{feature: `Reliable`, text: `Focus POS enables your business to operate without interruption as you manage tables, communicate orders to the kitchen, process payments—and maximize table turnover.`},
	{feature: `Fast, Accurate Ordering`, text: `At a stationary or mobile POS terminal, ordering is easy with images, graphic displays, custom screens, packaged commands, and modifiers.`},
];

const imageList = [
	{src: image1src, alt: 'image1'},
	{src: image2src, alt: 'image2'},
	{src: image3src, alt: 'image3'},
	{src: image4src, alt: 'image4'}
	];

const Home = ({onSelect}) => {
	return (
		<>
			<FPMImageSlideShow imageList={imageList} />
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h1>Restaurant POS Software</h1>
						<p>Say goodbye to workarounds and disjointed operations. Focus POS restaurant management systems turn workflows into simple, streamlined processes. Focus POS empowers you to control labor costs, efficiently manage inventory, and have deeper visibility into your business. Our restaurant point of sale (POS) systems are also designed to support the highest level of customer service, allowing you to create the dining experiences that will delight customers and earn their loyalty. Focus POS systems are PCI-compliant; easy to use, install and maintain; and deliver return on investment by maximizing operational efficiency and minimizing costs.</p>
					</div>
					<Link className='widget-link' to='/solutions' onClick={() => onSelect(0, null)}>
						Learn More
					</Link>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='Cs_ip9jrLWE' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image5src}>
					<h2 className='h2-green'>Restaurant POS Buyers Guide</h2>
					<p><strong>Need help?</strong> Read our step-by-step guide to making an informed purchasing decision.</p>
					<a className='widget-link' style={{backgroundColor: '#06b066', width: '250px'}} href='https://info.focuspos.com/acton/media/9799/download-your-free-buyers-guide' target='blank'>
						Download Buyers Guide
					</a>
				</FPMPlacard>
			</div>
			<div className='row-2'>
				<div className='column'>
					<img className='exp-img' src={image6src} alt='image7'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<IconContext.Provider value={{ className: 'fg-arrow-right' }}>
							<FPMFeatureList
								title='Focus POS Benefits'
								itemList={list1}
								listBullet={<FaArrowRight className='fg-arrow-right'/>}
								bulletGap='2em'
							/>
						</IconContext.Provider>
					</div>
				</div>
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<h2>Solutions We Offer</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400', textAlign: 'center'}}>Choose from our wide range of offerings to design a comprehensive restaurant POS and management solution for your business.</p>
				</div>
			</div>
			<div className='row' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image8' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>myFocus</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>The myFocus Cloud includes mobile management and alerts, cloud-based reporting, customer loyalty, and gift card solutions.</p>
							<Link className='widget-link' to='/myfocus' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image13src} alt='image9' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Mobile POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Streamline workflows and provide quicker, more accurate service using Focus POS on a mobile device to take orders and process payments tableside.</p>
							<Link className='widget-link' to='/solutions/mobile-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image14src} alt='image10' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>iPad POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Focus POS is compatible with iPad tablets for an aesthetically pleasing, sleek, modern countertop point of sale terminal or for an easy-to-use mobile solution.</p>
							<Link className='widget-link' to='/solutions/ipad-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image15src} alt='image11' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Multi-Check Dashboard</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Manage checks from all areas of your business—table, carry-out, delivery—from one screen and perform multiple functions without closing the dashboard.</p>
							<Link className='widget-link' to='/solutions/multi-check-dashboard' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image16src} alt='image12' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Restaurant Online Ordering</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Give your customers the convenience of placing orders in advance and streamline in-house processes with our fully integrated solution.</p>
							<Link className='widget-link' to='/solutions/restaurant-online-ordering' onClick={() => onSelect(1, 1)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image17src} alt='image13' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>nFocus</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Our nFocus report dashboard gives you easy access to the data you need to manage employees, oversee operations and to make better business decsions.</p>
							<Link className='widget-link' to='/solutions/nfocus' onClick={() => onSelect(2, 5)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image18src} alt='image14' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Focus as a Subscription</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Give your business the advantages of a top-of-the-line, scalable Focus POS system on a budget-friendly, monthly subscription basis. Reduce risk and upgrade for free.</p>
							<Link className='widget-link' to='/solutions/focus-as-a-subscription' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image19src} alt='image15' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Tablet POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Expand your POS capabilities with tablet POS. Training is easy: the Focus POS interface is the same on your tablet or stationary POS terminal.</p>
							<Link className='widget-link' to='/solutions/tablet-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<h2>Industries We Serve</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400', textAlign: 'center'}}>Focus POS offers point of sale solutions for a variety of industries</p>
				</div>
			</div>
			<div className='row' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-item'>
							<img className='exp-img' src={image20src} alt='image16' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Table Service</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Our table service POS system provides a variety of features that help to increase average ticket size, increase table turnover, improve customer service, and streamline food preparation.</p>
							<Link className='widget-link' to='/industries/table-service-pos' onClick={() => onSelect(3, 5)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image21src} alt='image17' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Quick Service</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>The quick service industry requires employees to work at faster pace and more efficiently than ever before. An easy-to-use, intelligent interface to streamline order-taking is essential.</p>
							<Link className='widget-link' to='/industries/quick-service-pos' onClick={() => onSelect(3, 0)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image22src} alt='image18' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Fast Casual</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Fast casual restaurants have emerged as one of the most popular forms of dining. Business people, tourists, and everyone in between are always on the lookout for a place to enjoy a quick lunch.</p>
							<Link className='widget-link' to='/industries/fast-casual-pos' onClick={() => onSelect(3, 1)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image23src} alt='image19' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Bar</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Patrons frequent bars and night clubs to unwind, the last thing they want to do is wade through crowds and wait in lines. The ability to manage tabs efficiently is critical to streamlining speed of service.</p>
							<Link className='widget-link' to='/industries/bar-pos' onClick={() => onSelect(3, 3)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image24src} alt='image20' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Café</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Unlike traditional restaurants, cafés and coffee shops receive most of their traffic in the morning. Customers expect to receive their beverages and breakfast in a hurry.</p>
							<Link className='widget-link' to='/industries/cafe-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image25src} alt='image21' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Delivery</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>When customers call a restaurant to order take-out or delivery, they want the experience to be as quick and painless as possible — from phone call to receipt of their order.</p>
							<Link className='widget-link' to='/industries/delivery-pos' onClick={() => onSelect(3, 2)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image26src} alt='image22' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Frozen Yogurt</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>The right frozen yogurt POS system looks great, keeps your shop organized, and improves customer loyalty. You need a reliable, scalable frozen yogurt POS system that will grow with you.</p>
							<Link className='widget-link' to='/industries/frozen-yogurt-pos' onClick={() => onSelect(3, 6)}>
								Learn More
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>);
}

export default Home;