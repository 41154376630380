import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/multi-check-dashboard-solution_bg.jpg';
import image2src from '../images/split-checks-with-multi-check-dashboard.png';
import image3src from '../images/happy-restaurant-visitors-multi-check-dashboard.png';
import glimg1src from '../images/Picture1-1024x808.png';
import glimg2src from '../images/Picture11.png';
import glimg3src from '../images/Picture10.png';
import glimg4src from '../images/Picture9.png';
import glimg5src from '../images/Picture8.png';
import glimg6src from '../images/Picture7.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
    {src: glimg6src, alt: 'glimg6'},
	];

const MultiCheckDB = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Multi-Check Dashboard' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Why Use Time and Effort When You Don’t Have To?</h2>
						<p>How much time could your staff save by eliminating multiple clicks (and potential for error) each time they close, split, or print a check? The Focus POS system Multi-Check Dashboard empowers your staff to do their jobs faster, more efficiently and more accurately. Free servers from spending extra time navigating the point of sale (POS) system to find what they need, and enable them to focus their attention on customers, upselling, cross-selling and increasing average sales.</p>
                        <p>Our revolutionary Multi-Check Dashboard interface reduces the number of touches it takes to add items to a check or check out a customer. Servers can effortlessly manage all of their checks and tables through one screen, and customer service isn’t delayed as servers scroll and tap through multiple screens to find the correct check to modify or close. The Focus POS system Multi-Check Dashboard also empowers you to configure screens to provide optimal support for your unique operation and your team.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='JoVl0A-dJjg' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image1src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Multi-Check Dashboard Brochure</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Multi-Check-Dashboard-Brochure_web.pdf' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
            <div className='row-2'>
                <div className='column'>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
                <div className='column'>
					<div className='text-section'>
						<h2>Greater Visibility, Faster Service</h2>
						<p>With the increase in carry-out and delivery popularity throughout the industry, your restaurant is challenged to track orders and sales from a variety of revenue streams. With the Focus POS system Multi-Check Dashboard, your staff can open and view all checks at once and perform multiple functions on multiple checks, including adding items to an order or splitting checks, without leaving the dashboard. The operational efficiency you gain with the Multi-Check Dashboard can help you create more convenient, seamless dining experiences for your customers</p>
					</div>
				</div>
			</div>
            <div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
            <div className='row-2'>
                <div className='column'>
					<div className='text-section'>
						<h2>Focus POS System Multi-Check Dashboard Features</h2>
						<ul>
							<li>Simultaneously manage all checks and tables</li>
							<li>Easily split, print, and close checks</li>
							<li>Add menu items to checks</li>
							<li>Create up to 30 customizable commands</li>
							<li>Apply special or promotional discounts</li>
							<li>Configure carryout and delivery separately from in-house orders</li>
							<li>Complete transactions with high-speed payment card processing</li>
						</ul>
					</div>
				</div>
                <div className='column'>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
			</div>
            <div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default MultiCheckDB;