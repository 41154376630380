import {FaAngleRight} from 'react-icons/fa';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMPlacard from '../components/FPM-Placard';
import FPMFeatureLinkList from '../components/FPM-FeatureLinkList';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/delivery-pos.png';
import image2src from '../images/table-service-pos1.png';
import image3src from '../images/AdobeStock_66210426_Preview-crop.png';
import image4src from '../images/table-service-pos2.png';
import image5src from '../images/restaurant-owner-table-service-pos.png';
import glimg1src from '../images/TS-02a-1.png';
import glimg2src from '../images/TS-03a-1.png';
import glimg3src from '../images/TS-05a-1.png';
import glimg4src from '../images/TS-06a-2-1.png';
import glimg5src from '../images/TS-09a-1.png';
import glimg6src from '../images/TS-01a-1.png';

const lkList = [
	{href: '/industries/bar-pos', menuid: 3, itemid: 3, text: 'Bar'},
	{href: '/industries/pizza-pos', menuid: 3, itemid: 7, text: 'Pizzeria'},
	{href: '/industries/nightclub-pos', menuid: null, itemid: null, text: 'Nightclub'},
	{href: '/industries/mexican-restaurant-pos', menuid: null, itemid: null, text: 'Mexican'},
	{href: '/industries/table-service-pos', menuid: 3, itemid: 5, text: 'Table Service'},
	{href: '/industries/food-truck-pos', menuid: null, itemid: null, text: 'Food Truck'},
	{href: '/industries/quick-service-pos', menuid: 3, itemid: 0, text: 'Quick Service'},
	{href: '/industries/ice-cream-pos', menuid: null, itemid: null, text: 'Ice Cream'},
	{href: '/industries/cafe-pos', menuid: null, itemid: null, text: 'Café'},
	{href: '/industries/frozen-yogurt-pos', menuid: 3, itemid: 6, text: 'Frozen Yogurt'},
	{href: '/industries/coffee-shop-pos', menuid: 3, itemid: 4, text: 'Coffee Shop'},
	{href: '/industries/franchise-pos', menuid: null, itemid: null, text: 'Franchise'},
	{href: '/industries/fast-casual-pos', menuid: 3, itemid: 1, text: 'Fast Casual'}
	];

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const RestaurantPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Restaurant POS' />
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Restaurant POS: The Key to a Successful Business</h2>
						<p>Some people compare restaurant point of sale (POS) systems to electronic cash registers – but they do so much more. Restaurant point of sale (POS) software helps you control labor costs, manage inventory, and have deeper insights into sales and operations. It also gives your team the ability to enhance customer experiences with faster service, greater efficiency, and more convenient types of transactions, such as online ordering, order and pay at the table or curbside, and omnichannel gift cards and loyalty rewards.</p>
						<p>It&rsquo;s important to keep in mind that although there are hundreds of point of sale systems on the market, not all of them are designed for restaurant operations. Give your business the advantages of software specifically designed for the way you do business, from taking orders and communicating them to the right prep station to accepting online orders for delivery by DoorDash or Uber Eats or managing your takeout and delivery business.</p>
					</div>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
				<div className='column'>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image3src}>
					<h2 style={{color: '#fff'}}>Download the free e-book Everything to Know About Restaurant Point of Sale Software</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/fs/blocks/showLandingPage/a/9799/p/p-0086/t/page/fm/0' target='blank'>
						Download Our Ebook
					</a>
				</FPMPlacard>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Why Focus POS is the Right Choice for Your Business</h2>
						<p>Focus POS has a long history of helping businesses succeed. Focus POS includes industry-leading features that enable your staff to deliver excellent customer service and dining experiences. In addition, Focus POS&rsquo; reliable software increases efficiency with a suite of seamlessly integrated products that allow you to optimize all areas of your business.</p>
						<p>Focus POS also backs its solutions with technical and customer support that ensure your system always provides maximum value to your restaurant.</p>
					</div>
				</div>
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Focus Restaurant POS Features</h2>
						<ul>
							<li>Sales data that helps you identify trends, adapt menus, and change pricing as needed</li>
							<li>Integration with accounting to simplify tax forms, profit and loss statements and other vital documents</li>
							<li>PCI-compliant solutions and enhanced controls that minimize the risks of employee theft and fraud</li>
							<li>Labor management features such as time clock functionality and scheduling</li>
							<li>Cloud-based management and reporting that enables anytime, anywhere access to data and administrative capabilities</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image5src} alt='image5'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<FPMFeatureLinkList
						columns={2}
						title='Focus POS Has a Solution for Your Business'
						description='Focus POS is the ideal solution for businesses in the entire range of restaurant industry segments, including:'
						listBullet={<FaAngleRight className='li-img'/>}
						itemList={lkList} 
						onSelect={onSelect}
					/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#ffffff'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
		</>);
}

export default RestaurantPOS;