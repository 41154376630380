import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/coupon-on-phone-customer-loyalty-program.png';
import glimg1src from '../images/myfocus-loyalty-software-mockup6-1024x853.png';
import glimg2src from '../images/myfocus-loyalty-software-mockup5-1024x853.png';
import glimg3src from '../images/myfocus-loyalty-software-mockup4-1024x853.png';
import glimg4src from '../images/myfocus-loyalty-software-mockup3-1024x853.png';
import glimg5src from '../images/myfocus-loyalty-software-mockup3-1024x853.png';
import glimg6src from '../images/myfocus-loyalty-software-mockup1-1024x853.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'},
	];

const CustLoyalProg = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Customer Loyalty Program' />
			</div>
			<div className='row' style={{paddingBotton: 0}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Reward Your Most Faithful Customers With Our Loyalty Program for POS</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0}}>
				<div className='column'>
					<div className='text-section'>
						<p>Our POS loyalty program is more than just a rewards card. Focus&rsquo;s Loyalty Program for POS, part of the myFocus Cloud Solutions, is a comprehensive solution that features cloud storage, multistore processing, card or cardless accounts, promotional coupons, surveys, marketing lists, email blasts, and text-based marketing.</p>
						<p>In addition to cloud storage, a Facebook plugin can allow your customers to register their cards and check balances directly from your Facebook page. That way, you can create more opportunities to market your restaurant to your most valued customers.</p>
						<p>Also, our restaurant point of sale rewards program supports various loyalty rewards plans, giving you the flexibility to test different options to see which results in the greatest return for your business. Our POS Loyalty Program&rsquo;s rewards plans include:</p>
						<ul>
							<li><span style={{fontWeight: 700}}>Points:</span> Points are assigned to menu items, and when customers accumulate enough points, they receive a reward.</li>
							<li><span style={{fontWeight: 700}}>Sales:</span> Customers earn points for each dollar they spend.</li>
							<li><span style={{fontWeight: 700}}>&quot;Around the World&quot;:</span> Customers earn points for purchasing a variety of different menu items.</li>
							<li><span style={{fontWeight: 700}}>Incremental:</span> Customers can attain different levels based on visits or dollar amounts.</li>
						</ul>
						<p>Custom mobile applications can be designed for any restaurant. They can be accessed via mobile devices and smartphones, and our Loyalty Program for POS can combine with myFocus gift cards to encourage repeat business.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='aiCOFTEVw7o' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus Loyalty + Gift?</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/media/9799/focus-loyalty--gift' target='blank'>
						Download Loyalty + Gift Brochure
					</a>
				</div>
			</div>
			<div className='row' style={{paddingBotton: 0, backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Benefit from Our POS Loyalty Program&rsquo;s Features</h2>
					</div>
				</div>
			</div>
			<div className='row-2-1' style={{paddingTop: 0, backgroundColor: '#f4f4f4'}}>
				<div className='column-2'>
					<div className='text-section'>
						<p>Research shows that most consumers join and use restaurant point of sale rewards programs to earn points, but it may be one of many loyalty programs they signed up for. So, keep your loyalty program offers—and your brand—in front of customers with ongoing customer engagement activities.</p>
						<p>Our Loyalty Program for POS empowers you to use your customer loyalty program to generate powerful marketing lists. Use these lists to engage customers on an ongoing basis with emails that include coupons, news of upcoming events, and other marketing promotions. Other features from our POS Loyalty Program include:</p>
						<ul>
							<li>myFocus cloud storage</li>
							<li>Multistore processing</li>
							<li>Social media integration</li>
							<li>iPhone app for customers</li>
							<li>Unlimited plans</li>
							<li>Promotional coupons</li>
							<li>Marketing list generation</li>
							<li>Email blasts</li>
							<li>Text message blasts (additional charges may apply)</li>
						</ul>
					</div>
				</div>
				<div className='column-1'>
					<img className='exp-img' src={image1src} alt='image1' style={{maxWidth: '400px'}} />
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#ffffff'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
		</>);
}

export default CustLoyalProg;