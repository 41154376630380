import {Link} from 'react-router-dom';
import FPMNavGroup from './FPM-NavGroup';
import FPMTopMenuList from './FPM-TopMenuList';
import FPMSlideMenu from './FPM-SlideMenu';

const FPMNavBar = ({menus, selected, sticky, onSelect}) => {
	return (
	<>
		<nav className='nav-bar sticky'>
			<FPMNavGroup id='brand-group'>
				<Link to='/' onClick={() => onSelect(0, null)}>
					<img src='/focus-only-logo-2.png' alt='Focus POS - Miami'/>
				</Link>
			</FPMNavGroup>
			<FPMNavGroup id='menu-group'>
				<FPMTopMenuList 
					menus={menus} 
					selected={selected}
					onSelect={onSelect}
				/>
			</FPMNavGroup>
			<FPMNavGroup id='widget-group'>
				<Link className='widget-link' to='/contact-us/request-a-demo' onClick={() => onSelect(null, null)}>
					Request Demo
				</Link>
			</FPMNavGroup>
			<FPMNavGroup id='toggle-group'>
				<FPMSlideMenu 
					menus={menus} 
					selected={selected}
					onSelect={onSelect}
				/>
			</FPMNavGroup>
		</nav>
		</>);
}

export default FPMNavBar;