import FPMPageTitle from '../components/FPM-PageTitle';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/focuson1.png';
import image2src from '../images/focus_focuson-brochure-cta-background.jpg';
import image3src from '../images/focuson3.png';
import glimg1src from '../images/ss3-1-654x1024.png';
import glimg2src from '../images/ss1-1-654x1024.png';
import glimg3src from '../images/ss2-1-654x1024.png';
import glimg4src from '../images/ss4-1-654x1024.png';
import glimg5src from '../images/ss5-654x1024.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	];

const FocusOn = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Mobile POS App: FocusON' />
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Take Focus On-the-Go With Our POS Phone App</h2>
						<p>Don&rsquo;t let a stationary POS system keep your restaurant tied down. With FocusON, you can always take our mobile POS app with you anytime you need it. Our POS phone app is a novel mobile solution that allows you to take customer orders and process transactions whenever and wherever you need.</p>
						<p>Also, FocusON enables you to leverage your mobile device as an ordering and payment solution, essentially a portable POS system. So whether you&rsquo;re running your food truck, managing a concession booth at a fair or festival, or operating as a street vendor in the big city, our mobile POS app provides the functionality you need.</p>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image2src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free FocusON Brochure</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/media/9799/focuson-brochure' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row-2-1'>
				<div className='column-1' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image3src} alt='image3' style={{maxWidth: '400px'}} />
				</div>
				<div className='column-2'>
					<div className='text-section'>
						<h2>Take Back Control With Our Portable POS Features</h2>
						<p>Are you looking to make transactions from your restaurant&rsquo;s patio or cater an event? Or maybe you want to take your food truck downtown or run a concession booth at a local arts festival? Whatever goals you have for your restaurant, our portable POS is ready to go. There are several reasons why you would need to accept payments on the go, so first, make sure you&rsquo;re equipped with the tools to do it. Our mobile POS app offers features such as:</p>
						<ul>
							<li>Splitting checks between groups of customers</li>
							<li>Accepting multiple payments on a single check</li>
							<li>Taking mobile wallet payments from apps like Apple Pay</li>
							<li>Securely processing payments with EMV chip cards</li>
							<li>Capturing signatures from customers electronically</li>
							<li>Emailing customer receipts</li>
							<li>Providing customer feedback</li>
						</ul>
						<p>Plus, you can speed up the order-taking process while on the go by leveraging a POS phone app that gives you any time, anywhere functionality. With industry-leading mobile order and mobile payment technology, you can unlock greater productivity and efficiency.</p>
						<p style={{fontWeight: 700}}>FocusON allows you to:</p>
						<ul>
							<li>Open a new check with customers wherever you are, without needing to re-enter it</li>
							<li>Pick up an existing tab whenever servers change or before guests have finished and closed out</li>
							<li>Support complex orders that span multiple seats and close out each one separately</li>
							<li>Continue to use the same modifiers you’re familiar with on your Focus POS no matter where you are</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>);
}

export default FocusOn;