import FPMPageTitle from '../components/FPM-PageTitle';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/restaurant-carding-card-ipad-pos.png';
import image2src from '../images/tablet-pos-solution-brochure_bg.jpg';
import image3src from '../images/focus-pos-software-ipad-pos.png';
import glimg1src from '../images/ipad-pos-software-mockup2-1024x621.png';
import glimg2src from '../images/ipad-pos-software-mockup1-1024x621.png';
import glimg3src from '../images/ipad-pos-software-mockup2-1-1024x621.png';
import glimg4src from '../images/ipad-pos-software-mockup3-1024x621.png';
import glimg5src from '../images/ipad-pos-software-mockup4-1024x621.png';
import glimg6src from '../images/ipad-pos-software-mockup5-1024x621.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
    {src: glimg6src, alt: 'glimg6'},
	];

const IPadPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='iPad POS' />
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Utilize the Power of iOS With Our POS for Apple Products</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>The Focus iPad POS solution can take your mobile point of sale to the next level by coupling our industry-leading point of sale (POS) software with the intuitiveness of the popular iOS platform. With the extensive features and supreme reliability of our traditional POS software, Focus’s POS for Apple products gives your staff the tools they need to get the job done. So watch as your restaurant operates more efficiently and offers customers a memorable experience they expect and deserve.</p>
						<p>In addition, our iPad point of sale is versatile and ready for any situation. You can use it as a stationary POS system that helps conserve valuable counter space or leverage the system for tableside ordering and line-busting during peak hours. No matter how you run your restaurant, our iPad POS system will adapt to your operational style. For example, you can customize menu screens so your staff can quickly locate popular menu items, so customers can get what they want when they want it.</p>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image2src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Tablet POS Brochure</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/media/9799/download-tablet-pos-brochure' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
            <div className='row' style={{paddingBottom: 0, backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Benefit from Our iPad Point of Sale’s Robust Range of Features</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>The Focus iPad POS solution allows your restaurant to streamline front and back-office operations to maximize your profitability. Apple’s intuitive interface allows your staff to be trained quickly on our POS software. With specialized menu layouts and packaged commands, you can enable your team to quickly service customers while pop-up alerts remind them to suggest additional items.</p>
						<p>Also, our iPad point of sale provides you robust inventory and employee management for the back office and automated enterprise reports to give you visibility into your operations to make the most profitable business decisions. Some of our other POS for Apple features include:</p>
						<ul>
							<li>Apple’s familiar interface allows you to train new and current employees effortlessly</li>
							<li>Customizable menu layouts for convenience and efficiency</li>
							<li>Packaged commands to assist you with quick order entry</li>
							<li>Notifications to remind workers to suggest the selling of additional items</li>
							<li>Compatible with hardware peripherals, including receipt printers</li>
							<li>Fast and secure credit card processing</li>
							<li>Streamlined employee scheduling and hour management</li>
                            <li>Click-of-a-button enterprise reporting and data analysis</li>
						</ul>
					</div>
				</div>
			</div>
            <div className='row' style={{backgroundColor: '#fff'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
            <div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default IPadPOS;