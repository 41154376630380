import {FaAngleRight} from 'react-icons/fa';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMFeatureList from '../components/FPM-FeatureList';
import image1src from '../images/pizza1.png';
import image2src from '../images/pizza2.png';
import image3src from '../images/pizza3.png';
import image4src from '../images/pizza4.png';
import image5src from '../images/46th-street-logo.png';
import image6src from '../images/board-brew.jpg';
import image7src from '../images/green-mill-pizza.jpg';
import image8src from '../images/JohnnysHorizontal_Red-01.jpg';
import image9src from '../images/fast-food-1.png';
import image10src from '../images/mockup_4-new-must-have-technologies-for-your-restaurant.png';
import image11src from '../images/mockup_5-ways-tablet-pos-increases-restaurant-revenue.png';
import image12src from '../images/mockup_calculate-restaurant-roi-from-pos.png';
import image13src from '../images/Focus_future-proof-your-restaurant_book2.png';

const list1 = [
	{text: `Customer-facing displays to help support suggestive selling, tip prompts, and sign-on screen for credits cards`},
	{text: `Rapid ordering with seamless combo functions for multiple item entry`},
	{text: `Further streamlined orders with custom menus and screens`},
	];

const list2 = [
	{text: `Assure order accuracy by recalling previous orders`},
	{text: `Provide additional sale suggestions for maximized revenue`},
	{text: `Include modifiers for custom orders`},
	{text: `Organize accounts by household for optimal organization`},
	];

const list3 = [
	{text: `Generate detailed business reports`},
	{text: `Automatically receive reports at convenient times`},
	{text: `View inventory updates in real-time`},
	];

const list4 = [
	{text: `FocusPay can integrate into your pizza POS system so that you can safely conduct EMV transactions, providing your customers with more ways to pay`},
	{text: `myFocus Loyalty helps you engage customers even when they’re not at your pizzeria. You can send emails with coupons and other marketing promotions as incentives to keep them coming back for more`},
	{text: `Focus Mobile Alerts allow you to manage your pizza shop and access vital information whenever and wherever you go on your mobile device`},
	];

const list5 = [
	{text: `Quick and effortless retrieval to onsite service and sales`},
	{text: `Autonomy to operate with your partners to manage and control credit card fees`},
	{text: `Highly dependable software your pizzeria can count on`},
	];

const PizzaPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Pizza POS' />
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>A Pizza POS for Raking in the Dough</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>Pizza has always been a popular dish. Able to tailor toppings to their preferences, customers usually order pizza when they’re looking for a convenient meal. Whether a pizzeria offers a dine-in area, pick up, delivery, or a combination of the three, every customer demands two things: convenience and customization.</p>
						<p>This is where a pizza POS system can help. Focus POS offers intuitive software for pizzerias that streamlines the order and delivery processes for employees. In addition to making sure the pizza gets to the customer while it’s still hot, a pizza POS system also helps ensure that drivers take the correct orders to their respective customers, allowing pizza shop owners to enhance the profitability and efficiency of their businesses.</p>
						<p>Focus POS also integrates with Uber Eats and DoorDash,,so your customers can use those services to have your menu items delivered to their doors.</p>
						<p>As important as speed and accuracy are for pizzerias, owners are also concerned with inventory, sales, payment options, marketing, and much more. Even though every pizza shop sells the same type of product, customers and ownership know that every pizza is unique. That’s why Focus POS designs pizza shop software that is as customizable as a pizza’s toppings, giving you the recipe for success.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='iG9kBRdvT64' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Satisfy More than Your Customers’ Appetites'
							description='While your pizza may be enough to make your customers happy, it’s important to do whatever you can to maximize customer satisfaction from the moment they walk through the door. Our pizza POS can provide your customers an experience they’ll appreciate, with features like:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list1}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Empower Your Pizzeria Staff'
							description='Second to the pizza itself is the service it comes with. No matter how busy your Friday night rush is, ensure that every customer gets their order on the double, without a topping out of place. Our pizza shop software includes features to help employees provide the best service possible, allowing them to:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list2}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Elevate Back-End Operations with Pizza Shop Software'
							description='With feature-rich, back-end reporting, our software for pizzerias will help you gain maximum visibility into your business, allowing you to make informed decisions that directly affect the profitability of your pizza shop. Our Pizza POS will allow you to:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list3}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Enhance Your Pizza Shop Software System'
							description='Along with the Focus Pizza POS basics, you can provide a more enriched experience for your customers by adding our other state-of-the-art solutions:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list4}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>Customers</h2></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image5' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image6src} alt='image6' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image7src} alt='image7' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image8src} alt='image8' style={{maxWidth: '250px'}}/></div>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Why Choose Focus POS Software for Your Pizzeria?'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list5}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image9src} alt='image9'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>E-Books</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image10src} alt='image10' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>4 NEW Must-Have Technologies for Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/4-new-must-have-technologies-for-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image11src} alt='image11' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>5 Ways Tablet POS Increases Restaurant Revenue</p>
							<a className='widget-link' href='https://www.focuspos.com/5-ways-tablet-pos-increases-restaurant-revenue/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image12' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>How to Calculate Your Restaurant’s ROI from a POS Deployment</p>
							<a className='widget-link' href='https://www.focuspos.com/how-to-calculate-your-restaurants-roi-from-a-pos-deployment/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image13src} alt='image13' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Ways to Future-Proof Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/6-ways-to-future-proof-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PizzaPOS;