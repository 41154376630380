const FPMFeatureList = ({columns, title, titleFontSize, titleFontWeight, description, listBullet, bulletGap, itemList, textFontSize, textFontWeight}) => {
	const gridClass = columns => {
		switch (columns) {
		  case 2:
			return 'df-grid';
			break;
		  default:
			return 'sf-grid';
		}
	}
	const titleStyle = {fontSize: titleFontSize ? titleFontSize : 'inherited', fontWeight: titleFontWeight ? titleFontWeight : 'inherited'};
	const imgStyle = {paddingRight: bulletGap};
	const textStyle = {fontSize: textFontSize ? textFontSize : 'inherited', fontWeight: textFontWeight ? textFontWeight : 'inherited'};
	console.log(titleStyle);
	return(
		<>
		{title && <h2 style={titleStyle}>{title}</h2>}
		{description && <p>{description}</p>}
		<div className={gridClass(columns)}>
			{
				itemList.map((item, index) => {
					return(
						<div className='fg-cell' key={index}>
							<div className='fg-img' style={imgStyle}>
								{listBullet}
							</div>
							<div className='fg-text'>
								{item.feature && <h3>{item.feature}</h3>}
								{item.text && <p style={textStyle}>{item.text}</p>}
							</div>
						</div>
					);
				})
			}
		</div>
		</>
	)
}

FPMFeatureList.defaultProps = {
	columns: 1,
    bulletGap: '1em',
}

export default FPMFeatureList
