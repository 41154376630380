import {useRef} from 'react';
import {BsArrowsFullscreen} from 'react-icons/bs';
import {BsXLg} from 'react-icons/bs';

const FPMImgViewerOverlay = ({active, image, index, onClose}) => {
const viewerRef = useRef(null);

	let classStr = 'img-viewer-overlay';
	if (active) {
		classStr += ' active';
		document.body.style.overflow = 'hidden';
	}
	else {
		document.body.style.overflow = 'auto';
	}

    function toggleFullscreen(event) {
        event.stopPropagation();
        console.log('FullScreen');
        if (!document.fullscreenElement) {
          viewerRef.current.requestFullscreen().catch(err => {
            alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
          });
        } else {
          document.exitFullscreen();
        }
    }

	return(
		<div ref={viewerRef} className={classStr} onClick={onClose}>
            <div className='tool-bar'>
                <BsArrowsFullscreen className='btn' onClick={toggleFullscreen} />
                <BsXLg className='btn'/>
            </div>
            {image != null && <img className='img-zoom' src={image.src} alt={image.alt}/>}
        </div>
	);
}

export default FPMImgViewerOverlay;
