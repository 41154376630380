import {FaAngleRight} from 'react-icons/fa';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMFeatureList from '../components/FPM-FeatureList';
import image1src from '../images/quick-service1.png';
import image2src from '../images/quick-service2.png';
import image3src from '../images/quick-service3.png';
import image4src from '../images/quick-service4.png';
import image5src from '../images/Arbys-Logo.png';
import image6src from '../images/bft-logo.png';
import image7src from '../images/blimpie2.png';
import image8src from '../images/tacotime.png';
import image9src from '../images/line-of-people-quick-service-pos.png';
import image10src from '../images/mockup_4-new-must-have-technologies-for-your-restaurant.png';
import image11src from '../images/mockup_5-ways-tablet-pos-increases-restaurant-revenue.png';
import image12src from '../images/mockup_calculate-restaurant-roi-from-pos.png';
import image13src from '../images/Focus_future-proof-your-restaurant_book2.png';

const list1 = [
	{text: `Monitoring and reducing service times`},
	{text: `Online ordering integration so customers can skip the line and order their favorite meals from the convenience of their mobile devices`},
	{text: `Conversational ordering and order confirmation capabilities help employees engage in quick, easy conversations with customers to ensure their orders are completed correctly`},
	];

const list2 = [
	{text: `Fingerprint logins so your staff has quick and secure access to your POS software`},
	{text: `Sending orders from the POS system directly to the kitchen printers or kitchen display systems to streamline food production`},
	{text: `Managing multiple revenue centers—the counter, the drive-through window, and your online orders—on any screen in the store`},
	{text: `Drive-through check recall to keep workers organized even when customers are bumper-to-bumper`},
	];

const list3 = [
	{text: `Real-time reporting to monitor cash shortages, inventory shrinkage, comps, and voids`},
	{text: `Pulling labor and sales data to help you formulate a successful business plan for the future`},
	{text: `Countdown items to help you ensure that you don’t overuse a specific product to keep inventory levels balanced`},
	];

const list4 = [
	{text: `We can install FocusPay into your POS system so your restaurant can safely process EMV transactions, giving your customers different payment options`},
	{text: `myFocus Loyalty helps you reward your customers for their faithful dining at your restaurant. You can send emails with customized coupons and incentives to build stronger relationships between you and your customers.`},
	{text: `Take your restaurant on the go with Focus Mobile Alerts. Manage your restaurant and access critical data all from your mobile device.`},
	];

const list5 = [
	{text: `Quick and efficient retrieval to onsite sales and services`},
	{text: `Autonomy to choose your partners and control credit card processing fees`},
	{text: `The best and most reliable software`},
	];

const QuickSrvPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Quick Service POS' />
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Better Business in a Flash With Our Quick Service POS</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>Recently, the quick-service industry has witnessed higher demand for faster service and more efficiency. As a result, many quick-service restaurants have long lines, both at the counter and in the drive-thru, during peak periods. Unfortunately, all it takes is a simple slip-up (like giving a customer the wrong drink or charging the wrong amount) to cause costly customer service delays and the potential to lose even the most loyal customers. Luckily, you can avoid these mishaps when you have the right quick service software.</p>
						<p>Focus Quick Service POS offers an intuitive, intelligent interface to streamline order-taking, shave precious seconds off of service times, and quickly train new employees. With turnover rates in the fast-food industry sometimes rising over 100%, a quick serve POS that is easy to learn is imperative when constantly onboarding new staff. And with a robust arsenal of features and solutions, such as myFocus Loyalty or FocusPay, our quick service software guarantees the ultimate customer satisfaction while boosting the performance of your restaurant.</p>
						<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Quick-Service-Brochure_web.pdf' target='blank'>
							Learn More
						</a>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='iG9kBRdvT64' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Get Your Customers In-and-Out Without Breaking a Sweat'
							description='Speed and accuracy are the cornerstones of every quick-service restaurant. Let our quick serve POS help you avoid long lines and get customers out the door with features, such as:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list1}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Streamline Orders and Boost the Efficiency of Your Staff'
							description='Keep your customers and employees happy when you let our quick service POS do all the heavy lifting. Improve the effectiveness of your restaurant operations with features like:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list2}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Take Advantage of Valuable Data and Reporting'
							description='Keep a closer eye on your operations with our quick service software. Gain complete control over your restaurant with formidable back-end reporting and data analysis, all from your POS system. Some critical features included are:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list3}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Improve Your Quick Service POS Restaurant Management System'
							description='In addition to our Focus POS Restaurant Management Software basics, you can enhance customer satisfaction by adding our other top-of-the-line solutions.'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list4}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>Customers</h2></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image5' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image6src} alt='image6' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image7src} alt='image7' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image8src} alt='image8' style={{maxWidth: '250px'}}/></div>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Why Choose Focus Quick Service Software?'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list5}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image9src} alt='image9'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>E-Books</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image10src} alt='image10' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>4 NEW Must-Have Technologies for Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/4-new-must-have-technologies-for-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image11src} alt='image11' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>5 Ways Tablet POS Increases Restaurant Revenue</p>
							<a className='widget-link' href='https://www.focuspos.com/5-ways-tablet-pos-increases-restaurant-revenue/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image12' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>How to Calculate Your Restaurant’s ROI from a POS Deployment</p>
							<a className='widget-link' href='https://www.focuspos.com/how-to-calculate-your-restaurants-roi-from-a-pos-deployment/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image13src} alt='image13' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Ways to Future-Proof Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/6-ways-to-future-proof-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default QuickSrvPOS;