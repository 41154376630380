const FPMBrandBox = ({brand}) => {
    const prodLabel = (brand.products && brand.products.length > 1)?'Products':'Product';
	
    return(
		<div className='brand-box'>
            <div className='img-div'>
                <a href={brand.href} target='blank'>
				    <img src={brand.src} alt={brand.alt}/>
                </a>
			</div>
			<div className='txt-div'>
				<p><span style={{fontWeight: 700}}>{prodLabel}:</span> {brand.products.join(', ')}</p>
				<a className='lk-item' href={brand.href} target='blank'>{brand.text}</a>
			</div>
		</div>
	)
}

export default FPMBrandBox
