import {Link} from 'react-router-dom';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMBrandGalery from '../components/FPM-BrandGalery';
import brimg1src from '../images/partner_qsr-automations.jpg';
import brimg2src from '../images/partner_open-table.jpg';
import brimg3src from '../images/partner_seven-rooms.jpg';
import brimg6src from '../images/partner_togo-technologies.jpg';
import brimg7src from '../images/partner_olo.jpg';
import brimg9src from '../images/partner_patron-path.jpg';
import brimg10src from '../images/partner_mkonnekt.jpg';
import brimg11src from '../images/partner_itsacheckmate.jpg';
import brimg12src from '../images/partner_cheftec.jpg';
import brimg13src from '../images/partner_foodtrak.jpg';
import brimg14src from '../images/partner_optimum-control.jpg';
import brimg15src from '../images/partner_sculpture-hospitality.jpg';
import brimg16src from '../images/yellow-dog.jpg';
import brimg17src from '../images/partner_avero.jpg';
import brimg19src from '../images/partner_par-data-central.jpg';
import brimg20src from '../images/partner_qsr-online.jpg';
import brimg21src from '../images/partner_decision-logic.jpg';
import brimg22src from '../images/partner_margin-edge.jpg';
import brimg23src from '../images/partner_profit-sword.jpg';
import brimg24src from '../images/partner_restaurant-systems-pro.jpg';
import brimg25src from '../images/Restaurant-365.jpg';
import brimg26src from '../images/partner_rasi.jpg';
import brimg27src from '../images/chef-tab.jpg';
import brimg28src from '../images/partner_berg.jpg';
import brimg29src from '../images/partner_barvision.jpg';
import brimg30src from '../images/partner_easybar.jpg';
import brimg31src from '../images/partner_beer-saver.jpg';
import brimg32src from '../images/partner_digital-pour.jpg';
import brimg33src from '../images/partner_control-plus-gl.jpg';
import brimg34src from '../images/napa-tech-nav-logo.png';
import brimg35src from '../images/dtiq.jpg';
import brimg36src from '../images/partner_remote-eyes.jpg';
import brimg37src from '../images/partner_solink.jpg';
import brimg38src from '../images/partner_paychex.jpg';
import brimg39src from '../images/partner_adp.jpg';
import brimg40src from '../images/partner_gratuity-solutions.jpg';
import brimg41src from '../images/partner_quickbooks.jpg';
import brimg42src from '../images/partner_sage.jpg';
import brimg43src from '../images/partner_hot-schedules.jpg';
import brimg44src from '../images/partner_card-market.jpg';
import brimg45src from '../images/partner_netmasons.jpg';
import brimg46src from '../images/partner_repeat-returns.jpg';
import brimg47src from '../images/partner_levelup.jpg';
import brimg48src from '../images/partner_paystone.jpg';
import brimg49src from '../images/partner_paytronix.jpg';
import brimg50src from '../images/partner_spendgo.jpg';
import brimg51src from '../images/partner-punchh.jpg';
import brimg52src from '../images/partner_etouch-menu.jpg';
import brimg53src from '../images/partner_express-check.jpg';
import brimg54src from '../images/partner_vemos.jpg';
import brimg55src from '../images/partner_agilysys.jpg';
import brimg56src from '../images/partner_oracle.jpg';
import brimg57src from '../images/partner_shift4-payments.jpg';
import brimg58src from '../images/partner_worldpay.jpg';
import brimg59src from '../images/partner-truist.png';
import brimg60src from '../images/partner_evo-payments.jpg';
import brimg61src from '../images/partner_touch-dynamic.jpg';
import brimg62src from '../images/partner_crs.jpg';
import brimg63src from '../images/partner_toshiba.jpg';
import brimg64src from '../images/partner_aures.jpg';
import brimg65src from '../images/partner_posiflex.jpg';
import brimg66src from '../images/partner_partnertech.jpg';
import brimg67src from '../images/partner_elo.jpg';
import brimg68src from '../images/partner_scansource.jpg';
import brimg69src from '../images/partner_bluestar.jpg';
import brimg70src from '../images/partner_mscd.jpg';
import brimg71src from '../images/partner_metsales.jpg';


const brandList1 = {
	title: 'Reservations', 
	list: [
		{products: ['QSR Hostess', 'DineTime'], src: brimg1src, alt: 'brimg1', text: 'www.qsrautomations.com', href: 'https://www.qsrautomations.com/'},
		{products: ['Open Table'], src: brimg2src, alt: 'brimg2', text: 'www.opentable.com', href: 'https://www.opentable.com/'},
		{products: ['Seven Rooms'], src: brimg3src, alt: 'brimg3', text: 'sevenrooms.com', href: 'https://sevenrooms.com/en/'},
	],
};

const brandList2 = {
	title: 'Online Ordering', 
	list: [
		{products: ['Paytronix'], src: brimg49src, alt: 'brimg5', text: 'www.paytronix.com', href: 'https://www.paytronix.com/platform/order-delivery/'},
		{products: ['ToGo Technologies'], src: brimg6src, alt: 'brimg6', text: 'home.togotechnologies.com', href: 'https://home.togotechnologies.com/'},
		{products: ['OLO'], src: brimg7src, alt: 'brimg7', text: 'www.olo.com', href: 'https://www.olo.com/'},
		{products: ['Patron Path'], src: brimg9src, alt: 'brimg9', text: 'patronpath.com', href: 'https://patronpath.com/'},
		{products: ['mKonnekt'], src: brimg10src, alt: 'brimg10', text: 'www.mkonnekt.com', href: 'https://www.mkonnekt.com/'},
	],
};

const brandList3 = {
	title: 'Delivery Management', 
	list: [
		{products: ['ItsaCheckmate'], src: brimg11src, alt: 'brimg11', text: 'www.itsacheckmate.com', href: 'https://www.itsacheckmate.com/'},
	],
};

const brandList4 = {
	title: 'Inventory Management', 
	list: [
		{products: ['ChefTec'], src: brimg12src, alt: 'brimg12', text: 'www.cheftec.com', href: 'https://www.cheftec.com/'},
		{products: ['FOOD-TRAK'], src: brimg13src, alt: 'brimg13', text: 'www.foodtrak.com', href: 'http://www.foodtrak.com/'},
		{products: ['Optimum Control'], src: brimg14src, alt: 'brimg14', text: 'tracrite.net', href: 'https://tracrite.net/'},
		{products: ['Sculpture Hospitality'], src: brimg15src, alt: 'brimg15', text: 'www.sculpturehospitality.com', href: 'https://www.sculpturehospitality.com/'},
		{products: ['Yellow Dog Software'], src: brimg16src, alt: 'brimg16', text: 'www.yellowdogsoftware.com', href: 'https://yellowdogsoftware.com/'},
	],
};

const brandList5 = {
	title: 'Reporting & Analytics', 
	list: [
		{products: ['Avero'], src: brimg17src, alt: 'brimg17', text: 'averoinc.com', href: 'https://averoinc.com/'},
		{products: ['Data Central'], src: brimg19src, alt: 'brimg19', text: 'www.partech.com', href: 'https://www.partech.com/products/data-central/'},
		{products: ['QSR Online'], src: brimg20src, alt: 'brimg20', text: 'go.qsronline.com', href: 'https://go.qsronline.com/'},
		{products: ['Decision Logic'], src: brimg21src, alt: 'brimg21', text: 'www.decisionlogic.co', href: 'https://decisionlogic.co/'},
		{products: ['Margin Edge'], src: brimg22src, alt: 'brimg22', text: 'www.marginedge.com', href: 'https://www.marginedge.com/'},
		{products: ['Profit Sword'], src: brimg23src, alt: 'brimg23', text: 'profitsword.com', href: 'https://profitsword.com/'},
		{products: ['Restaurant Systems Pro'], src: brimg24src, alt: 'brimg24', text: 'restaurantsystemspro.net', href: 'https://restaurantsystemspro.net/'},
		{products: ['Restaurant 365'], src: brimg25src, alt: 'brimg25', text: 'www.restaurant365.com', href: 'https://www.restaurant365.com/'},
		{products: ['RASI'], src: brimg26src, alt: 'brimg26', text: 'rasiusa.com', href: 'https://rasiusa.com/'},
	],
};

const brandList6 = {
	title: 'KDS (Kitchen Display System)', 
	list: [
		{products: ['Chef TAB'], src: brimg27src, alt: 'brimg27', text: 'chef-tab.com', href: 'https://chef-tab.com/'},
		{products: ['QSR Automations'], src: brimg1src, alt: 'brimg28', text: 'www.qsrautomations.com', href: 'https://www.qsrautomations.com/'},
	],
};

const brandList7 = {
	title: 'Liquor Controls', 
	list: [
		{products: ['Berg'], src: brimg28src, alt: 'brimg29', text: 'www.bergliquorcontrols.com', href: 'https://www.bergliquorcontrols.com/'},
		{products: ['BarVision'], src: brimg29src, alt: 'brimg30', text: 'barvision.com', href: 'https://barvision.com/'},
		{products: ['Easybar'], src: brimg30src, alt: 'brimg31', text: 'easybar.com', href: 'https://easybar.com/'},
		{products: ['BeerSAVER'], src: brimg31src, alt: 'brimg32', text: 'www.usbeersaver.com', href: 'http://www.usbeersaver.com/'},
		{products: ['DigitalPour'], src: brimg32src, alt: 'brimg33', text: 'digitalpour.com', href: 'http://digitalpour.com/'},
		{products: ['BevIntel'], src: brimg15src, alt: 'brimg34', text: 'www.sculpturehospitality.com', href: 'https://www.sculpturehospitality.com/'},
		{products: ['Control Plus GL'], src: brimg33src, alt: 'brimg35', text: 'controlplusgl.com', href: 'https://controlplusgl.com/'},
		{products: ['Napa Technology'], src: brimg34src, alt: 'brimg36', text: 'www.napatechnology.com', href: 'https://www.napatechnology.com/'},
	],
};

const brandList8 = {
	title: 'Camera', 
	list: [
		{products: ['DTiQ'], src: brimg35src, alt: 'brimg37', text: 'www.dtiq.com', href: 'https://www.dtiq.com/'},
		{products: ['Remote Eyes'], src: brimg36src, alt: 'brimg38', text: 'www.remoteeyes.com', href: 'https://www.remoteeyes.com/'},
		{products: ['Solink'], src: brimg37src, alt: 'brimg39', text: 'solink.com', href: 'https://solink.com/'},
	],
};

const brandList9 = {
	title: 'Payroll', 
	list: [
		{products: ['Paychex'], src: brimg38src, alt: 'brimg40', text: 'www.paychex.com', href: 'https://www.paychex.com/'},
		{products: ['ADP'], src: brimg39src, alt: 'brimg41', text: 'www.adp.com', href: 'https://www.adp.com/'},
		{products: ['Gratuity Solutions'], src: brimg40src, alt: 'brimg42', text: 'gratuitysolutions.com', href: 'https://gratuitysolutions.com/'},
	],
};

const brandList10 = {
	title: 'Accounting', 
	list: [
		{products: ['Quickbooks'], src: brimg41src, alt: 'brimg43', text: 'quickbooks.intuit.com', href: 'https://quickbooks.intuit.com/'},
		{products: ['Sage'], src: brimg42src, alt: 'brimg44', text: 'www.sage.com', href: 'https://www.sage.com/'},
	],
};

const brandList11 = {
	title: 'Scheduling', 
	list: [
		{products: ['QSROnline'], src: brimg20src, alt: 'brimg45', text: 'go.qsronline.com', href: 'https://go.qsronline.com/'},
		{products: ['Hot Schedules'], src: brimg43src, alt: 'brimg46', text: 'www.fourth.com', href: 'https://www.fourth.com/hotschedules/'},
		{products: ['Decision Logic'], src: brimg21src, alt: 'brimg47', text: 'decisionlogic.co', href: 'https://decisionlogic.co/'},
	],
};

const brandList12 = {
	title: 'Online Gift Card Sales', 
	list: [
		{products: ['Card Market'], src: brimg44src, alt: 'brimg48', text: 'mycardmarket.com', href: 'https://mycardmarket.com/'},
		{products: ['Netmasons'], src: brimg45src, alt: 'brimg49', text: 'www.netmasons.com', href: 'http://www.netmasons.com/'},
	],
};

const brandList13 = {
	title: 'Loyalty', 
	list: [
		{products: ['Repeat Returns'], src: brimg46src, alt: 'brimg50', text: 'repeatreturns.com', href: 'https://repeatreturns.com/'},
		{products: ['Level Up'], src: brimg47src, alt: 'brimg51', text: 'www.thelevelup.com', href: 'https://www.thelevelup.com/'},
		{products: ['Paystone'], src: brimg48src, alt: 'brimg52', text: 'www.paystone.com', href: 'https://www.paystone.com/'},
		{products: ['Paytronix'], src: brimg49src, alt: 'brimg53', text: 'www.paytronix.com', href: 'https://www.paytronix.com/'},
		{products: ['Spendo'], src: brimg50src, alt: 'brimg54', text: 'www.spendgo.com', href: 'https://www.spendgo.com/'},
		{products: ['Punchh'], src: brimg51src, alt: 'brimg55', text: 'punchh.com', href: 'https://punchh.com/'},
	],
};

const brandList14 = {
	title: 'Mobile Ordering/Mobile Payments', 
	list: [
		{products: ['eTouch Menu'], src: brimg52src, alt: 'brimg56', text: 'etouchmenu.com', href: 'https://etouchmenu.com/'},
		{products: ['ExpressCheck'], src: brimg53src, alt: 'brimg57', text: 'citycheersmedia.com', href: 'https://citycheersmedia.com/expresscheck/'},
		{products: ['Vēmos'], src: brimg54src, alt: 'brimg58', text: 'www.vemos.io', href: 'https://www.vemos.io/focuspos-contactless-payments/'},
	],
};

const brandList15 = {
	title: 'Hotel/PMS', 
	list: [
		{products: ['Agilisys'], src: brimg55src, alt: 'brimg59', text: 'www.agilysys.com', href: 'https://www.agilysys.com/en/'},
		{products: ['Oracle'], src: brimg56src, alt: 'brimg60', text: 'www.oracle.com', href: 'https://www.oracle.com/'},
	],
};

const brandList16 = {
	title: 'Credit Card Processing', 
	list: [
		{products: ['Shift4 Payments'], src: brimg57src, alt: 'brimg61', text: 'www.shift4.com', href: 'https://www.shift4.com/'},
		{products: ['WorldPay'], src: brimg58src, alt: 'brimg62', text: 'www.fisglobal.com', href: 'https://www.fisglobal.com/en/merchant-solutions-worldpay?setLang=true'},
		{products: ['Truist'], src: brimg59src, alt: 'brimg63', text: 'www.truist.com', href: 'https://www.truist.com/small-business/merchant-services'},
		{products: ['EVO'], src: brimg60src, alt: 'brimg64', text: 'evopayments.com', href: 'https://evopayments.com/'},
	],
};

const brandList17 = {
	title: 'Hardware', 
	list: [
		{products: ['Touch Dynamic'], src: brimg61src, alt: 'brimg65', text: 'www.touchdynamic.com', href: 'https://www.touchdynamic.com/'},
		{products: ['CRS'], src: brimg62src, alt: 'brimg66', text: 'www.crs-usa.com', href: 'https://www.crs-usa.com/'},
		{products: ['Toshiba'], src: brimg63src, alt: 'brimg67', text: 'commerce.toshiba.com', href: 'https://commerce.toshiba.com/'},
		{products: ['Aures'], src: brimg64src, alt: 'brimg68', text: 'www.aures.com', href: 'https://www.aures.com/us/'},
		{products: ['Posiflex'], src: brimg65src, alt: 'brimg69', text: 'www.posiflex.com', href: 'http://www.posiflex.com/en-global'},
		{products: ['Partner Tech'], src: brimg66src, alt: 'brimg70', text: 'www.partnertechcorp.com', href: 'https://www.partnertechcorp.com/'},
		{products: ['Elo Touch Solutions'], src: brimg67src, alt: 'brimg71', text: 'www.elotouch.com', href: 'https://www.elotouch.com/'},
	],
};

const brandList18 = {
	title: 'Distribution', 
	list: [
		{products: ['Scansource'], src: brimg68src, alt: 'brimg72', text: 'www.scansource.com', href: 'https://www.scansource.com/en'},
		{products: ['BlueStar'], src: brimg69src, alt: 'brimg73', text: 'www.bluestarinc.com', href: 'http://www.bluestarinc.com/us-en/home.html'},
		{products: ['M-S Cash Drawer'], src: brimg70src, alt: 'brimg74', text: 'mscashdrawer.com', href: 'https://mscashdrawer.com/'},
		{products: ['Metropolitan Sales'], src: brimg71src, alt: 'brimg75', text: 'www.metsales.com', href: 'https://www.metsales.com/'},
	],
};

const Partners = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{padding: 0}}>
				<FPMPageTitle title='Partners' />
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<p style={{textAlign: 'center', maxWidth: '500px'}}>Focus’ open API platform offers integrations with popular hospitality and restaurant software. From reservation management to online ordering and delivery management, Focus delivers countless third party integrations and is always working to develop new software partnerships.</p>
					<div className='row' style={{justifyContent: 'center', padding: 0}}>
					<a className='widget-link' href='https://www.focuspos.com/contact-us/request-a-demo/' target='blank' style={{margin: '0 .5em', maxWidth: '100px'}}>
						Demo
					</a>
					<Link className='widget-link' to='/contact-us' onClick={() => onSelect(5, 1)} style={{margin: '0 .5em', minWidth: '220px'}}>
						Partner with Focus
					</Link>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyCosntent: 'flex-start'}}>
					<FPMBrandGalery brandList={brandList1}/>
					<FPMBrandGalery brandList={brandList2}/>
					<FPMBrandGalery brandList={brandList3}/>
					<FPMBrandGalery brandList={brandList4}/>
					<FPMBrandGalery brandList={brandList5}/>
					<FPMBrandGalery brandList={brandList6}/>
					<FPMBrandGalery brandList={brandList7}/>
					<FPMBrandGalery brandList={brandList8}/>
					<FPMBrandGalery brandList={brandList9}/>
					<FPMBrandGalery brandList={brandList10}/>
					<FPMBrandGalery brandList={brandList11}/>
					<FPMBrandGalery brandList={brandList12}/>
					<FPMBrandGalery brandList={brandList13}/>
					<FPMBrandGalery brandList={brandList14}/>
					<FPMBrandGalery brandList={brandList15}/>
					<FPMBrandGalery brandList={brandList16}/>
					<FPMBrandGalery brandList={brandList17}/>
					<FPMBrandGalery brandList={brandList18}/>
				</div>
			</div>
			
		</>);
}

export default Partners;