import {Link} from 'react-router-dom';
import FPMPageTitle from '../components/FPM-PageTitle';
import image1src from '../images/industry_bar-pos.jpg';
import image2src from '../images/industry_cafe-pos-250x225.jpg';
import image3src from '../images/industry_coffee-shop-pos.jpg';
import image4src from '../images/industry_delivery-250x225.jpg';
import image5src from '../images/industry_fast-casual.jpg';
import image6src from '../images/industry_food-truck-pos.jpg';
import image7src from '../images/industry_franchise-pos.jpg';
import image8src from '../images/industry_frozen-yogurt-250x225.jpg';
import image9src from '../images/industry_ice-cream-pos.jpg';
import image10src from '../images/industry_mexican-restaurant-pos.jpg';
import image11src from '../images/industry_night-club-pos.jpg';
import image12src from '../images/industry_pizza-pos.jpg';
import image13src from '../images/industry_quick-service.jpg';
import image14src from '../images/industry_table-service.jpg';

const Industries = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Industries' />
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<h2>Industries We Serve</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400', textAlign: 'center'}}>We offer point of sale solutions for a variety of industries. Learn more about our software, its features, and the ways your business can benefit from choosing Focus.</p>
				</div>
			</div>
			<div className='row' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-item'>
							<img className='exp-img' src={image1src} alt='image1' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Bar POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Patrons frequent bars and night clubs to unwind, the last thing they want to do is wade through crowds and wait in lines. The ability to manage tabs efficiently is critical to streamlining speed of service.</p>
							<Link className='widget-link' to='/industries/bar-pos' onClick={() => onSelect(3, 3)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image2src} alt='image2' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Café POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Unlike traditional restaurants, cafés and coffee shops receive most of their traffic in the morning. Customers expect to receive their beverages and breakfast in a hurry.</p>
							<Link className='widget-link' to='/industries/cafe-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image3src} alt='image3' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Coffee Shop POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Focus’ Coffee Shop POS gives baristas the power to keep lines moving fast by streamlining services with its intelligent interface.</p>
							<Link className='widget-link' to='/industries/coffee-shop-pos' onClick={() => onSelect(3, 4)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image4src} alt='image4' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Delivery POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>When customers call a restaurant to order take-out or delivery, they want the experience to be as quick and painless as possible — from phone call to receipt of their order.</p>
							<Link className='widget-link' to='/industries/delivery-pos' onClick={() => onSelect(3, 2)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image5src} alt='image5' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Fast Casual POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Fast casual restaurants have emerged as one of the most popular forms of dining. Business people, tourists, and everyone in between are always on the lookout for a place to enjoy a quick lunch.</p>
							<Link className='widget-link' to='/industries/fast-casual-pos' onClick={() => onSelect(3, 1)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image6src} alt='image6' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Food Truck POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Food trucks have exploded in popularity in recent years. Workers on lunch breaks, tourists, and people looking for a quick tasty meal can all appreciate the convenience of a food truck.</p>
							<Link className='widget-link' to='/industries/food-truck-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image7src} alt='image7' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Franchise POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Easily view and compare data from individual restaurants to determine trends, change menu items or prices, create promotions, and manage inventory levels.</p>
							<Link className='widget-link' to='/industries/franchise-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image8src} alt='image8' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Frozen Yogurt POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>The right frozen yogurt POS system looks great, keeps your shop organized, and improves customer loyalty. You need a reliable, scalable frozen yogurt POS system that will grow with you.</p>
							<Link className='widget-link' to='/industries/frozen-yogurt-pos' onClick={() => onSelect(3, 6)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image9src} alt='image9' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Ice Cream POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>For ice cream shop owners, serving customers quickly is the second most important part of the business – next to the ice cream itself.</p>
							<Link className='widget-link' to='/industries/ice-cream-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image10src} alt='image10' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Mexican Restaurant POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Our POS software accommodates English and Spanish speaking employees and customers which means servers can complete custom orders, print receipts, and checkout customers faster than ever.</p>
							<Link className='widget-link' to='/industries/mexican-restaurant-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image11src} alt='image11' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Nightclub POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Manage multiple customer tabs with ease. Quickly serving drinks will not only keep customers happy, but also convince them to come back for more rounds or on other occasions.</p>
							<Link className='widget-link' to='/industries/nightclub-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image12' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Pizza POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Whether a pizzeria offers a dine-in area, pick up, delivery, or a combination of the three, every customer demands two things: speed and customizability.</p>
							<Link className='widget-link' to='/industries/pizza-pos' onClick={() => onSelect(3, 7)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image13src} alt='image11' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Quick Service POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>The quick service industry requires employees to work at faster pace and more efficiently than ever before. An easy-to-use, intelligent interface to streamline order-taking is essential.</p>
							<Link className='widget-link' to='/industries/quick-service-pos' onClick={() => onSelect(3, 0)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image14src} alt='image12' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Table Service POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Our table service POS system provides a variety of features that help to increase average ticket size, increase table turnover, improve customer service, and streamline food preparation.</p>
							<Link className='widget-link' to='/industries/table-service-pos' onClick={() => onSelect(3, 5)}>
								Learn More
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default Industries;