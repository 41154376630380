import FPMPageTitle from '../components/FPM-PageTitle';
import image1src from '../images/everything-to-know-about-restaurant-point-of-sale-software-1.jpg';
import image2src from '../images/bigstock-Man-and-woman-sitting-in-cafet-268753645.jpg';
import image3src from '../images/bigstock-Portrait-of-smiling-waitress-u-140466719.jpg';

const Cornerstone = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Everything You Need to Know About Restaurant Management Systems and Processes' />
			</div>
			<div className='row-2-1'>
				<div className='column-1'>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
				<div className='column-2'>
					<div className='text-section'>
						<h2>Everything You Need to Know About Restaurant Point of Sale Software</h2>
						<p style={{fontWeight: 700}}>In this guide, you’ll discover:</p>
						<ul>
							<li>The best POS software for restaurants</li>
							<li>Benefits of restaurant point of sale software</li>
							<li>Benefits of online restaurant management software</li>
							<li>How to choose a restaurant point of sale system</li>
							<li>How point of sale systems software is evolving and more</li>
						</ul>
						<a className='widget-link' href='https://www.focuspos.com/everything-to-know-about-restaurant-point-of-sale-software/' target='blank'>
							Read Guide
						</a>
					</div>
				</div>
			</div>
			<div className='row-2-1' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column-2'>
					<div className='text-section'>
						<h2>Everything You Need to Know About Restaurant Management</h2>
						<p style={{fontWeight: 700}}>In this guide, you’ll discover best practices for:</p>
						<ul>
							<li>General restaurant management</li>
							<li>Must-have restaurant management software features</li>
							<li>Restaurant labor management</li>
							<li>Restaurant inventory management software</li>
							<li>Restaurant franchise management and more</li>
						</ul>
						<a className='widget-link' href='https://www.focuspos.com/everything-you-need-to-know-about-restaurant-management/' target='blank'>
							Read Guide
						</a>
					</div>
				</div>
				<div className='column-1'>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
			</div>
			<div className='row-2-1'>
				<div className='column-1'>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column-2'>
					<div className='text-section'>
						<h2>Everything You Need to Know About Tablet Point of Sale Software</h2>
						<p style={{fontWeight: 700}}>In this guide, you’ll discover:</p>
						<ul>
							<li>The overall benefits of tablet POS technology for businesses</li>
							<li>How point of sale systems software is evolving</li>
							<li>How mobile POS is changing the restaurant industry</li>
							<li>How tablet POS increases table Ticket Size and Revenue</li>
							<li>Benefits of tablet POS and Pay-at-the-Table Technology and more</li>
						</ul>
						<a className='widget-link' href='https://www.focuspos.com/everything-to-know-about-tablet-point-of-sale-software/' target='blank'>
							Read Guide
						</a>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>);
}

export default Cornerstone;