import FPMPageTitle from '../components/FPM-PageTitle';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/try-our-demo.png';
import image2src from '../images/focus_online-ordering-brochure-cta-background.jpg';
import image3src from '../images/people-using-mobile-devices-restaurant-online-ordering.png';
import image4src from '../images/Uber-Eats-Logo-e1621276693774-768x359.png';
import image5src from '../images/doordash-square-red-e1621276648124.jpg';
import image6src from '../images/man-using-phone-restaurant-online-ordering.png';
import glimg1src from '../images/OO1-1024x738.png';
import glimg2src from '../images/OO2-1024x740.png';
import glimg3src from '../images/OO3-1024x763.png';
import glimg4src from '../images/OO4a-1024x740.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'}
	];

const RestOnlineOrd = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Restaurant Online Ordering' />
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Compete by Offering Restaurant Online Ordering</h2>
						<p>Restaurant online ordering brings value and convenience to the customer experience, while being a powerful tool to increase your sales and grow your customer base. Now more than ever it is essential for restaurants to offer online and mobile ordering—according to the National Restaurant Association, 46% of smartphone users order restaurant takeout or delivery from their phones at least once a month.</p>
						<p>With Focus POS Online Ordering, you can seamlessly give your customers the convenience of placing delivery, takeout, or dine-in orders. Moreover, when customers can take their time browsing menu items and customizations, they tend to spend more, and are more likely to take advantage of upsell offers. Additionally, Focus Online Ordering helps expand your potential customer reach, while fostering loyalty with your most frequent customers. Focus POS Online Ordering lets you meet the expectations of the modern consumer, while helping your business grow.</p>
					</div>
				</div>
				<div className='column'>
					<img src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image2src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Online Ordering Brochure</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/media/9799/focus-online-ordering-brochure' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column'>
					<img src={image3src} alt='image3'/>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Increase Efficiency</h2>
						<p>Focus POS&rsquo; restaurant online ordering solution seamlessly integrates with Focus POS software for maximum efficiency. Orders are automatically integrated into your in-house system and streamlined for preparation. It&rsquo;s not necessary for an employee to take or enter orders manually, which saves labor hours and reduces errors. Focus Online Ordering also transmits orders directly to the kitchen, expediting food prep and ensuring orders are ready when customers arrive.</p>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#e8e8e8'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>NEW: Integration with Delivery</h2>
						<p>Focus POS now integrates with popular delivery apps like Uber Eats and DoorDash. Streamline operations while offering the convenient delivery options that customers demand. Even though much of the country is fully open for indoor dining and most people are excited to return to in-person events, people have grown accustomed to the many options currently available for obtaining food. If you&rsquo;re not already offering delivery via a popular app, you&rsquo;re likely missing out on a good number of sales. And if you already do offer your customers the option of Uber Eats or DoorDash delivery, you may be able to save valuable prep space and delivery time by streamlining all of your orders through one place: your Focus POS.</p>
						<img className='logo-img' src={image4src} alt='image4'/>
						<img className='logo-img' src={image5src} alt='image5'/>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Features of Focus&rsquo; Restaurant Online Ordering</h2>
						<ul>
							<li>Order directly from a mobile device</li>
							<li>Pay online with security and convenience</li>
							<li>Upsell the most profitable menu items</li>
							<li>Make updates to menu items and prices instantaneously</li>
							<li>Streamline workflows as orders are sent directly to the kitchen without additional staff intervention</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<img src={image6src} alt='image6'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>);
}

export default RestOnlineOrd;