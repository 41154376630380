import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/gift-card-program.png';
import glimg1src from '../images/Campaigns-1024x602.png';
import glimg2src from '../images/Filters-1024x556.png';
import glimg3src from '../images/Image-2-1024x545.png';
import glimg4src from '../images/Manage-Members.png';
import glimg5src from '../images/Plan-1-1024x583.png';
import glimg6src from '../images/ATW-1024x556.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const GiftCardProg = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Gift Card Program' />
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h1>Meet Demand and Build Revenue with a Gift Card Program</h1>
						<p>Restaurant gift cards continue to be a top choice for giving—and receiving. They may also be the preferred payment options for customers who don’t have credit cards, but don’t want to carry cash. Meet this demand and provide convenient gift card issues and payment experiences with Focus eGift and the myFocus gift card program. Customers can easily use digital eGift cards and cloud-based myFocus gift cards in any location of a restaurant chain, and our cards are processed in real time, so they can be used immediately following activation.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='UvaluOVQ74A' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
			<div className='row-2-1'>
				<div className='column-1'>
					<img className='exp-img' src={image1src} alt='image1' style={{maxWidth: '400px'}} />
				</div>
				<div className='column-2'>
					<div className='text-section'>
						<h2>Customer Convenience</h2>
						<p>With more customers ordering online, Focus eGift allows you to also sell gift cards when customers place their orders for curbside pickup or delivery. Our eGift cards are easy to purchase and send – and they’re just as easy to use when a customer is ordering from a PC, laptop, or smartphone. In addition, customers can store our eGift cards in Apple Wallet, so they’re ready to use on any channel.</p>
						<p>For customers who prefer a physical gift card, you can offer gift cards through myFocus Gift, part of the myFocus Cloud Solutions. Our gift card program gives your customers the added convenience of being able to check their gift card balances anytime, anywhere. This can also be a time-saving feature for your staff—customers won’t have to ask them to check balances at the register.</p>
						<p>And for even more convenience, my Focus gift cards can be combined with myFocus Loyalty so customers only need to carry one reloadable card for payment and to earn points toward valuable rewards.</p>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Focus eGift Features</h2>
						<p>Focus eGift allows your restaurant to capture gift card sales from customers ordering online. Our digital gift card program offers:</p>
						<ul>
							<li>Consumers can purchase eGift cards for others or themselves</li>
							<li>Simply enter recipient&rsquo;s email address and receive confirmation</li>
							<li>PCI-compliant solutions and enhanced controls that minimize the risks of employee theft and fraud</li>
							<li>Reloadable so they can be used indefinitely</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>myFocus Gift Features</h2>
						<p>The myFocus gift card program is a valuable stand-alone feature or can be combined with other Focus POS and myFocus features to create loyalty-building customer experiences. Our gift card program offers:</p>
						<ul>
							<li>Single location or restaurant chain processing</li>
							<li>Reloadable cards</li>
							<li>High-speed authorizations</li>
							<li>Easy access to balance and usage history</li>
						</ul>
					</div>
				</div>
			</div>
		</>);
}

export default GiftCardProg;