import FPMContactList from './FPM-ContactList';
import FPMSocialList from './FPM-SocialList';

const FPMContactBar = ({contactItems, socialItems}) => {
	return (
		<div className='contact-bar'>
			<FPMContactList contactItems={contactItems}/><FPMSocialList socialItems={socialItems}/>
		</div>);
}

export default FPMContactBar;