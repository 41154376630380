import {Link} from 'react-router-dom';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/mobile-phone-alert-mobile-management.png';
import glimg1src from '../images/Screen-Shot-2013-07-15-at-5.45.54-PM.png';
import glimg2src from '../images/Screen-Shot-2013-07-15-at-5.45.36-PM-1.png';
import glimg3src from '../images/Screen-Shot-2013-07-15-at-5.45.36-PM.png';
import glimg4src from '../images/M-Man-5-577x1024.png';
import glimg5src from '../images/M-Man-1-577x1024.png';
import glimg6src from '../images/M-Man-2-577x1024.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const MobileMng = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Remote Management' />
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Data and Insights Right at Your Fingertips</h2>
						<p>You count on your mobile device to keep you connected as you navigate your busy schedule. Why not also use it to help maintain oversight of your business? The Focus Mobile Alerts app lets you access the information you need to manage your restaurant from your smartphone or mobile device when you are commuting, working at a different location, or traveling.</p>
						<p>Use Focus Mobile Alerts, part of the myFocus Cloud Solutions, to review information including:</p>
						<div className='row-2' style={{padding: 0}}>
							<div className='column' style={{justifyContent: 'flex-start'}}>
								<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', margin: 0}}>Detailed Data</p>
								<ul>
									<li>Net sales</li>
									<li>Labor costs</li>
									<li>Check averages</li>
									<li>Sales by period</li>
									<li>Tracking items</li>
									<li>Sales by order type</li>
								</ul>
							</div>
							<div className='column' style={{justifyContent: 'flex-start'}}>
								<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700', margin: 0}}>Labor Information</p>
								<ul>
									<li>Overtime warnings</li>
									<li>Employees on the clock</li>
									<li>Employees by job</li>
									<li>Individual employees</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='cf3pF69dCUQ' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row-2-1'>
				<div className='column-1' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image1src} alt='image1' style={{maxWidth: '400px'}} />
				</div>
				<div className='column-2'>
					<div className='text-section'>
						<h2>Mobile Alerts</h2>
						<p>The Focus Mobile Alert system, built directly into your Focus POS system, allows you to create a risk profile for your business, which the system uses to monitor transactions and send real-time text alerts when a threshold is exceeded. When you are away from the restaurant, Focus Mobile Alerts will keep you informed with data that allows you to control costs and minimize loss, helping you to improve your restaurant’s bottom line.</p>
						<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700'}}>With Focus Mobile Alerts, you can set alerts for:</p>
						<ul>
							<li>Discount threshold</li>
							<li>Void threshold</li>
							<li>Variable price threshold</li>
							<li>Returns threshold</li>
							<li>Pay outs</li>
							<li>Offline credit card processing</li>
							<li>Batch problems</li>
							<li>Opening employee not clocked in</li>
							<li>End of day not processed</li>
							<li>Support notifications for close day and batch</li>
						</ul>
						<p>Focus Mobile Alerts also enables employee-triggered alerts with custom messages.</p>
						<p>Installation only takes a minute— Focus Mobile Alerts is available at iTunes® or Google Play. Focus Mobile Alerts is part of the <Link className='lk-item' to='/myfocus' onClick={() => onSelect(null, null)}>myFocus Cloud Solutions</Link> that includes myFocus Central, Loyalty, and Gift.</p>
						<Link className='widget-link' to='/myfocus' onClick={() => onSelect(null, null)}>
							Learn More
						</Link>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>);
}

export default MobileMng;