import {Link} from 'react-router-dom';
import {FaMinus, FaPlus} from 'react-icons/fa'
import FPMStackMenuBody from './FPM-StackMenuBody'

const FPMStackMenu = ({id, item, selected, expanded, onClose, onSelect, onExpand}) => {
	let classStr = 'stack-menu-div';
	/*if (id === expanded)
		classStr += ' stack-menu-expanded';*/
	return(
		<div className={classStr}>
			<div className='stack-menu-header'>
				<Link 
					className={selected.menu != null && id === selected.menu ? 'selected' : null}
					to={item.href} 
					onClick={() => {onClose(); onSelect(id, item.defaultItemId);}}>
						{item.text}
				</Link>
				{
					 (item.menuItems.length > 0) ?  ( (id === expanded) ? <FaMinus cursor='pointer' onClick={() => onExpand(expanded, id)}/> : <FaPlus cursor='pointer' onClick={() => onExpand(expanded, id)}/>) : null	
				}
			</div>
			{item.menuItems.length > 0 && <FPMStackMenuBody id={id} expanded={expanded} menuItems={item.menuItems} selected={selected} onClose={onClose} onSelect={onSelect}/>}
		</div>
	);
}

export default FPMStackMenu;
