import FPMPageTitle from '../components/FPM-PageTitle';
import image1src from '../images/mockup_4-new-must-have-technologies-for-your-restaurant.png';
import image2src from '../images/mockup_5-ways-tablet-pos-increases-restaurant-revenue.png';
import image3src from '../images/mockup_calculate-restaurant-roi-from-pos.png';
import image4src from '../images/Focus_future-proof-your-restaurant_book2.png';
import image5src from '../images/Focus-Restaurant-Technology_book.png';
import image6src from '../images/Focus_Preparedness-Guide-for-Restauranteurs_book.png';
import image7src from '../images/Everything-to-Know-about-Restaurant-Point-of-Sale-Software.png';
import image8src from '../images/Focus_new-era-of-restaurants.png';
import image9src from '../images/mockup_faas-brochure.png';
import image10src from '../images/mockup_myfocus-office-brochure.png';
import image11src from '../images/mockup_myfocus-cloud-overview.png';
import image12src from '../images/mockup_pos-buyers-guide.png';
import image13src from '../images/focus_company-brochure.png';
import image14src from '../images/mockup_table-service-brochure.png';
import image15src from '../images/mockup_quick-service-brochure.png';
import image16src from '../images/mockup_focus-mobile-brochure.png';
import image17src from '../images/mockup_multi-check-dashboard-brochure-1.png';
import image18src from '../images/mockup_delivery-pos-brochure-1.png';
import image19src from '../images/mockup_tablet-pos-brochure.png';
import image20src from '../images/mockup_cafe-service-brochure.png';
import image21src from '../images/mockup_bar-service-brochure.png';
import image22src from '../images/mockup_focuson-brochure.png';
import image23src from '../images/Focus_Online-Ordering-Brochure_book.png';
import image24src from '../images/Focus_loyalty-and-gift-brochure_book2.png';
import image25src from '../images/Focus_focuskitchen-brochure_book2.png';
import image26src from '../images/Focus_qrpay-brochure_book2.png';


const Resources = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Resources' />
			</div>
			<div className='row' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>E-Books</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image1src} alt='image1' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>4 NEW Must-Have Technologies for Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/4-new-must-have-technologies-for-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image2src} alt='image2' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>5 Ways Tablet POS Increases Restaurant Revenue</p>
							<a className='widget-link' href='https://www.focuspos.com/5-ways-tablet-pos-increases-restaurant-revenue/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image3src} alt='image3' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>How to Calculate Your Restaurant’s ROI from a POS Deployment</p>
							<a className='widget-link' href='https://www.focuspos.com/how-to-calculate-your-restaurants-roi-from-a-pos-deployment/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image4src} alt='image4' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Ways to Future-Proof Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/6-ways-to-future-proof-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image5src} alt='image5' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>7 Restaurant Technologies Crucial to Business Success</p>
							<a className='widget-link' href='https://www.focuspos.com/7-restaurant-technologies-crucial-to-business-success/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image6src} alt='image6' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Pandemic Preparedness Guide</p>
							<a className='widget-link' href='https://www.focuspos.com/pandemic-survival-recovery-and-preparedness-guide-for-restauranteurs/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image7src} alt='image7' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Everything to Know about Restaurant Point of Sale Software</p>
							<a className='widget-link' href='https://www.focuspos.com/everything-to-know-about-restaurant-point-of-sale-software/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image8src} alt='image8' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Technologies for the New Era of Restaurants</p>
							<a className='widget-link' href='https://www.focuspos.com/6-technologies-for-the-new-era-of-restaurants/' target='blank'>
								Download E-Book
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>Brochures</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image9src} alt='image9' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Focus as a Subscription</p>
							<a className='widget-link' href='https://www.focuspos.com/focus-as-a-subscription-brochure/' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image10src} alt='image10' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>myFocus Office Brochure</p>
							<a className='widget-link' href='https://www.focuspos.com/myfocus-office-brochure/' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image11src} alt='image11' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>myFocus Cloud Brochure</p>
							<a className='widget-link' href='https://www.focuspos.com/myfocus-cloud-brochure/' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image12' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Focus POS Buyers Guide</p>
							<a className='widget-link' href='https://www.focuspos.com/focus-pos-buyers-guide/' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image13src} alt='image13' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Company Brochure</p>
							<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image14src} alt='image14' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Table Service</p>
							<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Table-Service-Brochure_web.pdf' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image15src} alt='image15' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Quick Service</p>
							<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Quick-Service-Brochure_web.pdf' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image16src} alt='image16' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>myFocus Mobile</p>
							<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Focus-Mobile-Brochure_web.pdf' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image17src} alt='image17' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Multi-Check Dashboard</p>
							<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Multi-Check-Dashboard-Brochure_web.pdf' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image18src} alt='image18' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Delivery POS</p>
							<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Delivery-POS-Brochure_web.pdf' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image19src} alt='image19' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Focus Tablet POS</p>
							<a className='widget-link' href='https://www.focuspos.com/focus-tablet-pos-brochure/' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image20src} alt='image20' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Cafe POS</p>
							<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Cafe-POS-Brochure_web.pdf' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image21src} alt='image21' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Bar POS</p>
							<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Bar-Service-Brochure_web.pdf' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image22src} alt='image22' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>FocusON</p>
							<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_FocusON-Brochure_web.pdf' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image23src} alt='image23' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Focus Online Ordering</p>
							<a className='widget-link' href='https://www.focuspos.com/online-ordering-brochure/' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image24src} alt='image24' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Focus Loyalty + Gift</p>
							<a className='widget-link' href='https://www.focuspos.com/focus-loyalty-plus-gift-brochure/' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image25src} alt='image25' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>FocusKITCHEN</p>
							<a className='widget-link' href='https://www.focuspos.com/focuskitchen-brochure/' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image26src} alt='image26' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>Focus QRPay</p>
							<a className='widget-link' href='https://www.focuspos.com/focus-qrpay-brochure/' target='blank' style={{minWidth: '200px'}}>
								Download Brochure
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Resources;