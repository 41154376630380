import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/AdobeStock_66210426_Preview-crop.png';
import image2src from '../images/restaurant-owner-table-service-pos.png';
import image3src from '../images/focus_table-service-cta-background.jpg';
import image4src from '../images/waitress-screen-table-service-pos.png';
import glimg1src from '../images/TS-02a-1-1024x768.png';
import glimg2src from '../images/TS-03a-1-1024x768.png';
import glimg3src from '../images/TS-05a-1-1024x768.png';
import glimg4src from '../images/TS-06a-2-1-1024x768.png';
import glimg5src from '../images/TS-09a-1-1024x768.png';
import glimg6src from '../images/TS-01a-1-1024x768.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const TableSrvPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Table Service POS' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Table Service POS that Delivers the Experiences Your Customers Expect</h2>
						<p>Providing the types of dining experiences your customers want has become just as vital to business success as the irresistible items on your menu. Today’s consumers don’t have patience for long wait times due to inefficient processes or understaffing. They expect prompt, accurate and efficient service as a part of their fine dining or casual dining experiences.</p>
						<p>Your customers also hold your restaurant to a high standard when it comes to the technology you use to provide service. More and more, they expect conveniences such as online ordering and pay-at-the-table options, as well as the ability to seamlessly use their preferred payment methods.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='axqjQPqAZOY' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image1src}>
					<h2 style={{color: '#fff'}}>Learn Everything To Know about Restaurant POS</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/fs/blocks/showLandingPage/a/9799/p/p-0086/t/page/fm/0' target='blank'>
						Download Our EBook
					</a>
				</FPMPlacard>
			</div>
			<div className='row-2'>
				<div className='column'>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Focus POS Has the Table Service POS System You Need</h2>
						<p>Focus POS provides you with the point of sale (POS) functionality you need to craft customer experiences that build loyalty. Our table service POS systems allow you to provide convenient, customer-centric service from the moment a guest makes a reservation or walks through the door until the check is settled—and beyond with opportunities to continue to engage customers.</p>
						<p>Focus POS also helps you optimize efficiency in the back of house, with options such as kitchen display system (KDS) integration and meal-stage notifications for perfect course timing. We also help you manage your restaurant with labor scheduling and cloud-based reporting to give you the information you need anytime, anywhere.</p>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image3src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Table Service POS Brochure</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Table-Service-Brochure_web.pdf' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Top Focus POS Features for Table Service</h2>
						<p>Focus POS table service systems can be configured to support your unique business. With Focus POS, you can choose from these capabilities:</p>
						<ul>
							<li>Intuitive touchscreen interface</li>
							<li>Mobile POS and tableside ordering</li>
							<li>Secure credit card processing</li>
							<li>Table management and reservations</li>
							<li>Real-time labor management</li>
							<li>Reusable scheduling templates</li>
							<li>Sales data for accurate forecasting</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default TableSrvPOS;