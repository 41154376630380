import {FaAngleRight} from 'react-icons/fa';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMFeatureList from '../components/FPM-FeatureList';
import image1src from '../images/delivery-pos.png';
import image2src from '../images/man-delivering-pizza-delivery-pos.png';
import image3src from '../images/delivery2.png';
import image4src from '../images/delivery3.png';
import image5src from '../images/delivery4.png';
import image6src from '../images/46th-street-logo.png';
import image7src from '../images/board-brew.jpg';
import image8src from '../images/green-mill-pizza.jpg';
import image9src from '../images/JohnnysHorizontal_Red-01.jpg';
import image10src from '../images/delivery5.png';
import image11src from '../images/mockup_4-new-must-have-technologies-for-your-restaurant.png';
import image12src from '../images/mockup_5-ways-tablet-pos-increases-restaurant-revenue.png';
import image13src from '../images/mockup_calculate-restaurant-roi-from-pos.png';
import image14src from '../images/Focus_future-proof-your-restaurant_book2.png';

const list1 = [
	{text: `250 customizable screens so your employees can locate fan-favorite items and enter orders quickly and efficiently`},
	{text: `Processing credit card payments curbside to reduce credit card processing fees`},
	{text: `Integrated online digital maps for route optimization so customers never wait longer than necessary for their food`},
	];

const list2 = [
	{text: `Automatic price changes`},
	{text: `Review feature for reading back large orders`},
	{text: `Keeping delivery drivers road-ready with alerts for insurance and license expirations`},
	{text: `Delivery routing and zoning so your drivers don’t get lost or arrive late for deliveries`},
	];

const list3 = [
	{text: `Analyze your sales to create a long-term strategy`},
	{text: `Track inventory and manage shrinkage by leveraging feature-rich reporting and auditing`},
	{text: `Access to critical insight into customer behavior so you can improve marketing and promotions ROI and build a foundation for customer loyalty`},
	];

const list4 = [
	{text: `FocusPay is the perfect integration tool to help you safely process EMV transactions, giving your customers more payment options curbside or when the delivery driver arrives.`},
	{text: `myFocus Loyalty is a great way to engage with customers who don’t prefer seating in your dining room. You can email off-premises diners coupons and incentives to keep them coming back for more.`},
	{text: `Focus Mobile POS allows your team to manage pickup and delivery services on the go and share data with your POS system in real time.`},
	];

const list5 = [
	{text: `Uncomplicated access to onsite services and sales`},
	{text: `The ability to operate with your partners your way, helping you manage and control credit card fees`},
	{text: `The most reliable and self-sufficient software around`},
	];

const PickDelPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Pickup and Delivery POS' />
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Meet Customer Demands with POS for Pickup and Delivery</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>Customer preferences have shifted from onsite dining to curbside pickup and delivery. Focus POS has software for restaurants with features that makes it possible for you to prioritize orders, keep food warm and ready, and get the right orders to customers on time.</p>
						<p>Specifically designed for restaurants with high-volume delivery and take-out business, our POS solution allows employees to review customer order history and duplicate standard orders. In addition, delivery features allow for caller ID data capture on up to eight phone lines and save essential contact information about customers to streamline the order-taking process. Once you have the phone-in or online order, you can be sure that it is sent directly to your kitchen staff.</p>
						<p>And with added features, like mobile POS or myFocus Loyalty, you can find innovative ways to improve your curbside or delivery services while enticing customers with new deals and delicious rewards.</p>
						<p>Focus POS also integrates with Uber Eats and DoorDash, so your customers can use those services to have your menu items delivered to their doors.</p>
					</div>
					<img className='exp-img' src={image1src} alt='image1'/>
					<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Delivery-POS-Brochure_web.pdf' target='blank'>
						Learn More
					</a>
					
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='GfyxRk1DNiE' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Deliver More Than Just Great Food to Your Customers'
							description='Just as customers will remember excellent food, they’re also just as likely to remember quality customer service. Our software for restaurants can help you stand out from the rest of the crowd with features such as:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list1}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Keep Your Staff and Workplace Up and Running'
							description='Your workers have a lot on their plate to ensure that customers are satisfied and that the restaurant is running smoothly. Help them streamline operations with our POS software, with features that include:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list2}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Make Better Business Decisions With Critical Data Analysis'
							description='Keep tabs on your curbside pickup and delivery services with robust back-end reporting and data analysis. Our software can help you:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list3}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Optimize Curbside Pickup and Delivery With Mobile POS and Payment'
							description='Offer a better experience for your customers by adding these best-in-class solutions:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list4}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image5src} alt='image5'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>Customers</h2></div>
						<div className='wall-item'><img className='exp-img' src={image6src} alt='image6' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image7src} alt='image7' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image8src} alt='image8' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image9src} alt='image9' style={{maxWidth: '250px'}}/></div>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Why Choose Focus POS Software for Restaurants That Offer Pickup and Delivery?'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list5}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image10src} alt='image10'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>E-Books</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image11src} alt='image11' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>4 NEW Must-Have Technologies for Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/4-new-must-have-technologies-for-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image12' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>5 Ways Tablet POS Increases Restaurant Revenue</p>
							<a className='widget-link' href='https://www.focuspos.com/5-ways-tablet-pos-increases-restaurant-revenue/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image13src} alt='image13' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>How to Calculate Your Restaurant’s ROI from a POS Deployment</p>
							<a className='widget-link' href='https://www.focuspos.com/how-to-calculate-your-restaurants-roi-from-a-pos-deployment/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image14src} alt='image14' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Ways to Future-Proof Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/6-ways-to-future-proof-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PickDelPOS;