import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/quick-service-brochure_bg.jpg';
import glimg1src from '../images/franchise-pos-screenshot1.jpg';
import glimg2src from '../images/franchise-pos-screenshot2.jpg';
import glimg3src from '../images/franchise-pos-screenshot3.jpg';
import glimg4src from '../images/franchise-pos-screenshot4.jpg';
import glimg5src from '../images/franchise-pos-screenshot5.jpg';
import glimg6src from '../images/franchise-pos-screenshot6.jpg';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const FranchisePOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Franchise POS' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Store Performance Data at Your Fingertips</h2>
						<p>Focus Franchise POS options allow both the corporate office and franchises real-time access to critical store performance data. Easily view and compare data from individual restaurants to determine trends, change menu items or prices, create promotions, and manage inventory levels. Data is streamed to a centralized server throughout the day so you don’t have to wait until the next day to see how stores are performing. Access store level reports, enterprise reports, trends and graphs or even build custom reports.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='D-DoVTCvu5I' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-item'>
							<div className='text-section'>
								<h2>Reporting</h2>
								<ul>
									<li>Single Store Reporting</li>
									<li>Multi Store Reporting</li>
									<li>Online Reporting</li>
									<li>Smartphone Reporting</li>
								</ul>
							</div>
						</div>
						<div className='wall-item'>
							<div className='text-section'>
								<h2>Data Backup</h2>
								<ul>
									<li>Check File Backups</li>
									<li>Configuration Data Backup</li>
									<li>Local and Remote Backups</li>
									<li>Fully Automated</li>
								</ul>
							</div>
						</div>
						<div className='wall-item'>
							<div className='text-section'>
								<h2>Menu Downloads</h2>
								<ul>
									<li>Menu Prices</li>
									<li>Entire Menus</li>
									<li>Reports</li>
									<li>Images and Videos</li>
								</ul>
							</div>
						</div>
						<div className='wall-item'>
							<div className='text-section'>
								<h2>Corporate Messages</h2>
								<ul>
									<li>Employee Messages</li>
									<li>Messages by Jobs</li>
									<li>Messages at Clock In</li>
									<li>Must Read Messages Before Clockin</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default FranchisePOS;