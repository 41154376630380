import {FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube} from 'react-icons/fa'

const FPMSocialItem = ({id, item}) => {
	let renderIcon = null;
		switch(id) {
			case 1:
				renderIcon = <FaTwitter />;
			break;
			case 2:
				renderIcon = <FaLinkedinIn />;
			break;
			case 3:
				renderIcon = <FaYoutube />;
			break;
			default:
				renderIcon = <FaFacebookF />;
		}
	return(
		<>
			<a className='social-link' href={item.href}>{renderIcon}</a>
		</>
	);
}

export default FPMSocialItem;
