import FPMPageTitle from '../components/FPM-PageTitle';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/qrpay-image1a.png';
import image2src from '../images/focus_qrpay-brochure-cta-background.jpg';
import image3src from '../images/qrpay-image2a.png';
import image4src from '../images/qrpay-image3.png';
import glimg1src from '../images/Mobile-01-577x1024.png';
import glimg2src from '../images/Mobile-02-577x1024.png';
import glimg3src from '../images/Mobile-03-577x1024.png';
import glimg4src from '../images/Mobile-04-577x1024.png';
import glimg5src from '../images/Mobile-05-577x1024.png';
import glimg6src from '../images/Mobile-06-577x1024.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const QRPay = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Focus QRPay: A QR Code Payment Solution' />
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Revealing New Realms of Payment Possibilities</h2>
						<p>QRPay is an innovative addition to the Focus Cloud ecosystem. A touch-free QR code payment solution, it offers increased flexibility when it comes to making payments anytime, anywhere, and changing tip amounts, whether customers are in-house, curbside, or online.</p>
						<p>Deliver exceptional customer service and offer customers a touchless mobile payment option with QRPay.</p>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image2src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Focus QRPay Brochure</h2>
					<a className='widget-link white' href='https://info.focuspos.com/acton/fs/blocks/showLandingPage/a/9799/p/p-0088/t/page/fm/0' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row-2'>
				<div className='column'>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Fully Integrated with the Focus Cloud Suite</h2>
						<p>Fully integrated with the Focus Cloud Suite, QRPay adds a QR code to the customer&rsquo;s check. If dining in-house, the customer can scan the receipt with their smartphone and then view, pay, and add a tip to their bill with a credit card, Apple Pay or, coming soon, Google Pay.</p>
						<p>When ordering online with Focus Online Ordering, customers are presented with the QR code during pickup and they can add or adjust the tip amount in-house. Additionally, because it&rsquo;s fully integrated with the Focus Cloud Suite, it&rsquo;s compatible with FocusON, allowing you to accept mobile payments, anytime, anywhere.</p>
						<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700'}}>With Focus QRPay:</p>
						<ul>
							<li>Offer customers a touchless QR code payment solution</li>
							<li>Offer customers mobile touchless payment anytime, anywhere</li>
							<li>Customers can adjust tip amounts in-house, curbside, or online</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>Completely Tokenized and PCI Compliant</h2>
						<p>Focus is 100% committed to keeping sensitive customer data secure. That&rsquo;s why all of our payment solutions, including Focus QRPay, are fully tokenized and PCI compliant. You&rsquo;ll sleep more soundly knowing that your data is safe.</p>
					</div>
				</div>
				<div className='column'>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>);
}

export default QRPay;