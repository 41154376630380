import FPMContactItem from './FPM-ContactItem'

const FPMContactList = ({contactItems}) => {
	return(
		<div className='contact-list'>
			{
				contactItems.map((item, index) => {
					return(
					<FPMContactItem
						key={index}
						item={item}
					/>);
				})
			}
		</div>
	)
}

export default FPMContactList
