import {Link} from 'react-router-dom';

const FPMFeatureLinkList = ({columns, title, titleFontSize, titleFontWeight, description, listBullet, bulletGap, itemList, onSelect}) => {
	const gridClass = columns => {
		switch (columns) {
		  case 2:
			return 'dl-grid';
			break;
		  default:
			return 'sl-grid';
		}
	}
	const titleStyle = {fontSize: titleFontSize ? titleFontSize : 'inherited', fontWeight: titleFontWeight ? titleFontWeight : 'inherited'};
	const textStyle = {color: '#FAB132', paddingLeft: bulletGap};
	return(
		<>
		{title && <h2 style={titleStyle}>{title}</h2>}
		{description && <p>{description}</p>}
		<div className={gridClass(columns)}>
			{
				itemList.map((item, index) => {
					return(
                        <div key={index} className='lg-cell'>
                            <Link className='li' to={item.href} onClick={() => onSelect(item.menuid, item.itemid)}>
                                {listBullet}
                                {item.text && <span style={textStyle}>{item.text}</span>}
						    </Link>
                        </div>
					);
				})
			}
		</div>
		</>
	)
}

FPMFeatureLinkList.defaultProps = {
	columns: 1,
    bulletGap: '1em',
}

export default FPMFeatureLinkList
