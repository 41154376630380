import {FaYoutube} from 'react-icons/fa';

const FPMThumbBox = ({video, onPlay}) => {	
      const thumbHref = `https://www.youtube.com/watch?v=${video.ID}`;
      const thumbSrc = `https://i3.ytimg.com/vi/${video.ID}/hqdefault.jpg`;

      return(
        <div className='thumb-box'>
          <a
            className='img-div'
            href={thumbHref}
            onClick={(event) => {onPlay(event, video.ID);}}
          >
              <img src={thumbSrc} />
              <FaYoutube className='play-icon'/>
          </a>
          {video.caption != null && <a 
            className='lk-item'
            href={thumbHref}
            onClick={(event) => {onPlay(event, video.ID);}}
          >
              {video.caption}
          </a>}
        </div>
	    )
}

export default FPMThumbBox
