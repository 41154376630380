import {useState, useRef, useEffect} from 'react';

const FPMPlacard = ({children, imgUrl}) => {
	const y = useRef(0);
	const placardRef = useRef();
	const [backgroundPos, setBackgroundPos] = useState(0);
	const divStyle = {color: 'white'};
	if (imgUrl != null) {
		divStyle.backgroundImage = 'url(' + imgUrl + ')';
		divStyle.backgroundPosition = 'center ' + backgroundPos + '%';
	}
	
	useEffect(() => {
		function handleNavigation(e) {
      		const window = e.currentTarget;
			if (placardRef != null && placardRef.current != null){
				if (window.pageYOffset < placardRef.current.offsetTop + placardRef.current.offsetHeight - window.innerHeight) {
					if (backgroundPos !== 100) setBackgroundPos(100);
				} else if (window.pageYOffset < placardRef.current.offsetTop) {
					setBackgroundPos(parseInt(100 * ((placardRef.current.offsetTop - window.pageYOffset)/(window.innerHeight - placardRef.current.offsetHeight))));
				} else {
					if (backgroundPos !== 0) setBackgroundPos(0);
				}
			}
		}
		
		window.addEventListener('scroll', handleNavigation);
    	return _ => {
      		window.removeEventListener('scroll', handleNavigation);
		}
	}, [y, placardRef, backgroundPos]);
	
	return(
		<div ref = {placardRef} className='img-placard' style={divStyle}>
			{children}
		</div>
	)
}

export default FPMPlacard
