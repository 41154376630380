import FPMBrandBox from './FPM-BrandBox'

const FPMBrandGalery = ({brandList}) => {
	return(
		<div className='wall-auto'>
			{brandList.title != null && <div className='wall-title'><h2>{brandList.title}</h2></div>}
			{
				brandList.list.map((item, index) => {
					return(
						<FPMBrandBox key={index} brand={item}/>
					);
				})
			}
		</div>
	)
}

export default FPMBrandGalery
