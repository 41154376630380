import {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import FPMStackMenu from './FPM-StackMenu'

const FPMRightMenuList = ({active, menus, selected, onClose, onSelect}) => {
	const [expanded, setExpanded] = useState(-1);
	
	const toggleMenu = (expanded, id) => {
		expanded === id ?	setExpanded(-1) : setExpanded(id);
	}
	
	let classStr = 'right-menu-list';
	if (active) classStr += ' active';
	
	return(
		<div className={classStr}>
			{
				menus.map((item, index) => {
					return(
					<FPMStackMenu
						key={index}
						id={index}
						item={item}
						selected={selected}
						expanded={expanded}
						onClose = {onClose}
						onSelect = {onSelect}
						onExpand = {toggleMenu}
					/>);
				})
			}
			<div className='stack-menu-div' id='widget-stack'>
				<Link className='widget-link' to='/contact-us/request-a-demo' onClick={() => onSelect(4, 2)}>
					Request Demo
				</Link>
			</div>
		</div>
	)
}

FPMRightMenuList.propTypes = {
	name: PropTypes.bool,
	menus: PropTypes.array,
	selected: PropTypes.object,
	onClose: PropTypes.func,
	onSelect: PropTypes.func
}

FPMRightMenuList.defaultProps = {
	active: false,
	menus: null,
	selected: {menu: null, item: null},
	onClose: null,
	onSelect: null
};

export default FPMRightMenuList
