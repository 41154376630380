import {forwardRef} from 'react';
import FPMYouTubeClip from './FPM-YouTubeClip';

const FPMYouTubeViewerOverlay = forwardRef(({active, videoId, onClose}, ref) => {
	let classStr = 'yt-viewer-overlay';
	if (active) {
		classStr += ' active';
		document.body.style.overflow = 'hidden';
	}
	else {
		document.body.style.overflow = 'auto';
	}


	return(
		<div className={classStr} onClick={(event) => {onClose(event, ref);}}>
            {videoId != null && <FPMYouTubeClip ref={ref} videoId={videoId} width='75%' aspectX={16} aspectY={9} shadow={true} autoplay={true} onClose={onClose}/>}
        </div>
	);
});

export default FPMYouTubeViewerOverlay;
