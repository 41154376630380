import FPMDropDownMenu from './FPM-DropDownMenu'

const FPMTopMenuList = ({menus, selected, onSelect}) => {
	return(
		<div className='top-menu-list'>
			{
				menus.map((item, index) => {
					return(
					<FPMDropDownMenu
						key={index}
						id={index}
						item={item}
						selected={selected}
						onSelect = {onSelect}
					/>);
				})
			}
		</div>
	)
}

export default FPMTopMenuList
