import FPMContactBar from './FPM-ContactBar';

const FPMHeader = ({contactItems, socialItems, menus, selected, onSelect}) => {
	return (
		<header className='main-header'>
			<FPMContactBar contactItems={contactItems} socialItems={socialItems}/>
		</header>);
}

export default FPMHeader;
