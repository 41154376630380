const FPMAppOverlay = ({active, onClose}) => {
	let classStr = 'app-overlay';
	if (active) {
		classStr += ' active';
		document.body.style.overflow = 'hidden';
	}
	else {
		document.body.style.overflow = 'auto';
	}

	
	return(
		<div className={classStr} onClick={() => onClose()}/>
	);
}

export default FPMAppOverlay;
