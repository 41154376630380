import React, { useState, useEffect } from "react";

const FPMImageSlideShow = ({imageList}) => {
	const [activeImg, setActiveImg] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			const newActiveImg = activeImg === imageList.length - 1 ? 0 : activeImg + 1;
			setActiveImg(newActiveImg);
			}, 10000);
		return () => clearInterval(interval);
	},[imageList, activeImg]);
	
	if (imageList != null && imageList.length > 0) {
		return(
			<div className='slide-show'>
				<img className='spacer' src={imageList[0].src} alt={imageList[0].alt} />
				<div className='image-display'>
				{
					imageList.map((img, index) => {
						let classStr = 'slide-img';
						if (activeImg === index)
							classStr += ' active';
						return (
							<div className='slide' key={index}>
								<img className={classStr} src={img.src} alt={img.alt} />
							</div>
						)
					})
				}
				</div>
			</div>
		);
	}
	else
		console.log(`FPMImageSlideShow: No images toshow.`);
}

export default FPMImageSlideShow
