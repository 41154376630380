import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/bar-pos-brochure_bg.jpg';
import glimg1src from '../images/Bar-6-1024x768.png';
import glimg2src from '../images/Bar-4-1024x768.png';
import glimg3src from '../images/Bar-8-1024x768.png';
import glimg4src from '../images/Bar-9-1024x768.png';
import glimg5src from '../images/Bar-10-1024x768.png';
import glimg6src from '../images/Bar-1-1024x768.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const NightclubPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Nightclub POS' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Dance Your POS Troubles Away With Nightclub POS</h2>
						<p>Patrons go to nightclubs to dance and have a good time, so the last thing they want to do is wait for food and drinks. It’s important for staff and bartenders to have access to the right tools and features in order to quickly and efficiently place and track customer orders and payment information. Quickly serving drinks will not only keep customers happy, but also convince them to come back for more rounds or on other occasions. Our Nightclub POS system gives bartenders the power to manage every customer’s tab from a single screen.</p>
						<p>Nightclubs are prone to losing money due to liquor shrinkage, which is a result of free drinks, over-pouring, and failing to charge the correct amount for a cocktail. Focus’ Nightclub POS has auditing and theft prevention functionality, which produces liquor inventory reports that help cut down on stealing and loss of revenue. This software also has an age verification feature, so your nightclub can avoid the fines and lawsuits that come with fake IDs.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='7_vYfLVLPj8' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image1src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Bar POS Brochure</h2>
					<a className='widget-link white' href='https://focuspos.com/wp-content/uploads/2014/02/bar_service1.pdf' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row-3' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2 style={{maxWidth: '350px'}}>Speed of Service</h2>
						<ul>
							<li>Fast Nightclub Screens</li>
							<li>Repeat Rounds</li>
							<li>Quick Payment Tenders</li>
							<li>Shared Tabs</li>
							<li>Pre-Authorized Tabs</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2 style={{maxWidth: '350px'}}>Audit and Theft Prevention</h2>
						<ul>
							<li>Blind Checkouts</li>
							<li>Employee Performance Reports</li>
							<li>Liquor Management</li>
							<li>Customer Age Verification</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2 style={{maxWidth: '350px'}}>Bar Features</h2>
						<ul>
							<li>Pool Table Timers</li>
							<li>Customer Advances</li>
							<li>Split Checks</li>
							<li>VIP/Industry Pricing</li>
							<li>Quick Tabs</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default NightclubPOS;