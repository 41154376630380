import {FaAngleRight} from 'react-icons/fa';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMFeatureList from '../components/FPM-FeatureList';
import image1src from '../images/cafe1.png';
import image2src from '../images/cafe2.png';
import image3src from '../images/cafe3.png';
import image4src from '../images/cafe4.png';
import image5src from '../images/Flogo.gif';
import image6src from '../images/Cold_Stone_Creamery-Logo.wine_.png';
import image7src from '../images/Kahwa-Coffee.png';
import image8src from '../images/sunrise-titles_8d1ccfb3-b592-493b-9a8b-15e523cdf8f9_600x.png';
import image9src from '../images/morning-rush-cafe-pos.png';
import image10src from '../images/mockup_4-new-must-have-technologies-for-your-restaurant.png';
import image11src from '../images/mockup_5-ways-tablet-pos-increases-restaurant-revenue.png';
import image12src from '../images/mockup_calculate-restaurant-roi-from-pos.png';
import image13src from '../images/Focus_future-proof-your-restaurant_book2.png';

const list1 = [
	{text: `Customer-facing displays to help support suggestive selling, tip prompts, and sign-on screen for credit cards`},
	{text: `Rapid ordering with seamless combo functions for multiple item entry`},
	{text: `Further streamlined orders with custom menus and screens`},
	];

const list2 = [
	{text: `Fingerprint recognition for access control`},
	{text: `Multiple cash drawers on a station`},
	{text: `Large change-due screen`},
	{text: `User-friendly interface to streamline employee training`},
	];

const list3 = [
	{text: `Entering cup counts for inventory tracking`},
	{text: `Robust reporting and auditing to accurately manage shrinkage`},
	{text: `Analyzing your sales to determine a long-term strategy`},
	];

const list4 = [
	{text: `Focus Loyalty is vital in a cafe POS since you thrive on your regular customers coming back at the same time tomorrow`},
	{text: `Focus gift cards allow your customers to spread the love for your cafe or bakery to their friends and family`},
	{text: `Online Ordering is becoming more popular in coffee shop software, allowing customers to skip the lines through pre-ordering from their device`},
	];

const list5 = [
	{text: `Speedy, simple access to onsite sales and service`},
	{text: `Freedom to process with your own partners to control credit card fees`},
	{text: `Highly reliable software`},
	];

const CafePOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Café POS' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>A Café POS that Delivers Results</h2>
						<p>Focus POS knows that cafés and coffee shops have different software needs than other restaurants in the hospitality industry. That’s why we offer coffee shop software specifically designed to support your business.</p>
						<p>Cafés require a point of sale system that offers quick and easy order-taking, streamlines food and drink preparation, and expedites customer checkout. Our systems help your baristas meet demand during the busiest hours of the day. So whether it’s before work or at lunch–anytime your customers are looking to get their muffins and lattes, know that you can provide them with everything they need to get them out the door quickly.</p>
						<p>Focus POS software for coffee shops also ensures that your inventory of baked goodies and brewed beverages are adequately stocked to meet your customers’ demands. Plus, with our Focus Loyalty and gift card add-on programs, customers can earn points and promotional items with their daily cup o’ joe and even utilize a reloadable card for easy payment.</p>
						<a className='widget-link' href='https://www.focuspos.com/wp-content/uploads/2020/02/Focus_Delivery-POS-Brochure_web.pdf' target='blank'>
							Learn More With Our Café POS Brochure
						</a>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='At1ehmE6bq4' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Serve Your Coffee Shop Patrons'
							description='No matter how busy your morning rush is, ensure that every caffeine-craving customer gets in and out without a hassle. Our software for coffee shops includes features like:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list1}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Manage Your Baristas'
							description='Assure that your customers remember great coffee and excellent service by equipping your baristas with the best coffee shop software around. Our POS solution allows for:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list2}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Boost Your Business with Coffee Shop Software'
							description='With feature-rich, back-end reporting, your software for coffee shops allows you to have complete visibility over your operations–and the ability to make the best decisions to grow your revenue. Notable features include:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list3}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Enhance Your Cafe POS Restaurant Management System'
							description='In addition to Focus POS Restaurant Management Software basics, you can provide an even fuller experience for your customers by adding enhanced solutions.'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list4}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>Customers</h2></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image5' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image6src} alt='image6' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image7src} alt='image7' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image8src} alt='image8' style={{maxWidth: '250px'}}/></div>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Why Choose Focus POS for Your Coffee Shop Software?'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list5}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image9src} alt='image9'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>E-Books</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image10src} alt='image10' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>4 NEW Must-Have Technologies for Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/4-new-must-have-technologies-for-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image11src} alt='image11' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>5 Ways Tablet POS Increases Restaurant Revenue</p>
							<a className='widget-link' href='https://www.focuspos.com/5-ways-tablet-pos-increases-restaurant-revenue/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image12' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>How to Calculate Your Restaurant’s ROI from a POS Deployment</p>
							<a className='widget-link' href='https://www.focuspos.com/how-to-calculate-your-restaurants-roi-from-a-pos-deployment/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image13src} alt='image13' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Ways to Future-Proof Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/6-ways-to-future-proof-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default CafePOS;