import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMPlacard from '../components/FPM-Placard';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/quick-service-brochure_bg.jpg';
import glimg1src from '../images/QS-8-1024x768.png';
import glimg2src from '../images/QS-5-1024x768.png';
import glimg3src from '../images/QS-4-1024x768.png';
import glimg4src from '../images/QS-3-1024x768.png';
import glimg5src from '../images/QS-2-1024x768.png';
import glimg6src from '../images/QS-1-1024x768.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const FoodTruckPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Food Truck POS' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Keep Customers Happy and Coming Back For More With Food Truck POS</h2>
						<p>Food trucks have exploded in popularity in recent years, serving everything from classic meals and snacks to gourmet fare. For the most part, food trucks are a response to the demand for a quick and cheap meal in large metropolitan cities. Workers on lunch breaks, tourists, and people looking for a tasty meal can all appreciate the convenience of a food truck.</p>
						<p>Our Food Truck POS software offers the owners of these miniature restaurants a variety of features that help them provide fast and reliable service to customers. Focus’s POS systems are specifically designed to help food truck owners maximize the productivity and profitability of their businesses. Food trucks attract customers who are looking for a quick bite to eat, and our software makes sure the ordering process isn’t any longer than it needs to be.</p>
						<p>In addition to speeding up order times, our Food Truck POs software will help owners track inventory and sales, market to customers, offer more payment options, and more. Although every food truck follows the same basic business model, every business is different. That is why Focus POS is dedicated to helping you customize a Food Truck POS system that meets the needs of your business.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='iG9kBRdvT64' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{padding: 0}}>
				<FPMPlacard imgUrl={image1src}>
					<h2 style={{color: '#fff'}}>Learn More With Our Free Quick Service Brochure</h2>
					<a className='widget-link white' href='https://focuspos.com/wp-content/uploads/2014/02/quick_service1.pdf' target='blank'>
						Download Brochure
					</a>
				</FPMPlacard>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
			<div className='row-3' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Speed of Service</h2>
						<ul>
							<li>Custom menu layouts</li>
							<li>Intuitive combo functions</li>
							<li>High speed credit card processing</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Auditing and Control</h2>
						<ul>
							<li>Inventory reporting</li>
							<li>Sales reports</li>
							<li>Driver insurance and license expiration warnings</li>
						</ul>
					</div>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Additional Features</h2>
						<ul>
							<li>Customer-facing displays</li>
							<li>Digital signage and menu boards</li>
							<li>Mobile reports</li>
							<li>Conversational ordering</li>
							<li>Suggestive selling prompts</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
		</>
	);
}

export default FoodTruckPOS;