const FPMToggleMenuBtn = ({onToggle}) => {
	return(
		<div className='toggle-menu-btn' onClick={() => onToggle()}>
			<div className='top-stick' />
			<div className='mid-stick' />
			<div className='bot-stick' />
		</div>
	);
}

export default FPMToggleMenuBtn;
