import {Link} from 'react-router-dom';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import image1src from '../images/solution_mobile-pos.jpg';
import image2src from '../images/solution_ipad-pos.jpg';
import image3src from '../images/solution_tablet-pos.jpg';
import image4src from '../images/solution_multi-check-dashboard.jpg';
import image5src from '../images/solution_online-ordering.jpg';
import image6src from '../images/solution_nfocus.jpg';
import image7src from '../images/solution_focus-as-a-subscription.jpg';

const Solutions = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Restaurant POS Solutions' />
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<h2>Configure Your Restaurant POS System to Support the Way You Do Business</h2>
						<p>From promoting the perfect menu and providing great dining experiences to controlling inventory and labor costs, it can be a challenge to operate a successful, profitable restaurant—but you can overcome those challenges with Focus POS restaurant point of sale (POS) software. Our extensive experience providing restaurant POS solutions has taught us no two businesses are exactly alike, so Focus POS provides you with options that give you the features and functionality you need for your unique operation.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='axqjQPqAZOY' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
			<div className='row' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-item'>
							<img className='exp-img' src={image1src} alt='image1' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Mobile POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Eliminate the need to wait at a stationary terminal to enter orders or process payments. Focus Mobile POS enables your wait staff to provide on-the-spot service and memorable dining experiences.</p>
							<Link className='widget-link' to='/solutions/mobile-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image2src} alt='image2' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>iPad POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Focus iPad POS gives your staff the ease of use of the intuitive Apple interface and full-featured Focus POS software. This versatile combination can be used as traditional, stationary POS or for tableside applications.</p>
							<Link className='widget-link' to='/solutions/ipad-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image3src} alt='image3' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Tablet POS</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Your tablet can function as a stationary POS terminal or enable you to take orders tableside, process payments at the table, line bust, and more. Training is easy since staff will already be familiar with the interface.</p>
							<Link className='widget-link' to='/solutions/tablet-pos' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image4src} alt='image4' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Multi-Check Dashboard</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Revolutionize check handling and efficiency and productivity by managing checks from tables and delivery from one screen, and perform multiple check functions on multiple checks without closing the dashboard.</p>
							<Link className='widget-link' to='/solutions/multi-check-dashboard' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image5src} alt='image5' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Online Ordering</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Focus Online Ordering integrates with your POS, eliminating the need for an employee to enter orders and enabling the kitchen staff expedite food prep and have orders ready on time.</p>
							<Link className='widget-link' to='/solutions/restaurant-online-ordering' onClick={() => onSelect(1, 1)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image6src} alt='image6' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>nFocus Report Dashboard</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>The nFocus Dashboard provides and overview of the restaurant’s operations as well as detailed reports. It also features one-click icons for commonly used functions such as employees, time cards, and scheduling.</p>
							<Link className='widget-link' to='/solutions/nfocus' onClick={() => onSelect(2, 5)}>
								Learn More
							</Link>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image7src} alt='image7' style={{maxWidth: '275px'}}/>
							<p style={{color: '#02598f', fontSize: '18px', fontStyle: 'italic', fontWeight: '700', textAlign: 'center', maxWidth: '300px'}}>Focus as a Subscription</p>
							<p style={{textAlign: 'center', maxWidth: '300px'}}>Focus as a Subscription provides your restaurant with the same features of Focus POS on a monthly, subscription basis, lowering upfront costs and risk. With FAAS, you pay as you go.</p>
							<Link className='widget-link' to='/solutions/focus-as-a-subscription' onClick={() => onSelect(null, null)}>
								Learn More
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Solutions;