import {Link} from 'react-router-dom';

const FPMDropDownMenuBody = ({id, menuItems, selected, onSelect}) => {
	return(
		<>
			<ul className='dropdown-menu-body'>
			{
				menuItems.map((item, index) => {
					return(
						<li key={index}>
							<Link className={selected.menu != null && id === selected.menu && selected.item != null && index === selected.item ? 'selected' : null} to={item.href} onClick={() => {onSelect(id, index)}}>{item.text}</Link>
						</li>);
				})
			}
			</ul>
		</>
	)
}

export default FPMDropDownMenuBody
