import FPMPageTitle from '../components/FPM-PageTitle';
import FPMImgGalery from '../components/FPM-ImgGalery';
import image1src from '../images/multiple-devices-central-cloud-based-reporting.png';
import image2src from '../images/cloud-based-pos.png';
import image3src from '../images/my-focus-office-features2.png';
import image4src from '../images/happy-owners-central-cloud-based-reporting.png';
import glimg1src from '../images/Check-viewer-1024x797.png';
import glimg2src from '../images/Login-screen-1024x803.png';
import glimg3src from '../images/Loyalty-Dems-1024x765.png';
import glimg4src from '../images/Reports-1024x668.png';
import glimg5src from '../images/Trend-1024x683.png';
import glimg6src from '../images/Calander-View-1024x778.png';

const glImageList = [
	{src: glimg1src, alt: 'glimg1'},
	{src: glimg2src, alt: 'glimg2'},
	{src: glimg3src, alt: 'glimg3'},
	{src: glimg4src, alt: 'glimg4'},
	{src: glimg5src, alt: 'glimg5'},
	{src: glimg6src, alt: 'glimg6'}
	];

const CloudRepMng = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Cloud Reporting and Management' />
			</div>
			<div className='row-2'>
				<div className='column'>
					<div className='text-section'>
						<h2>How to Be Everywhere at Once</h2>
						<p>When you work remotely or manage multiple restaurant locations, you need the right tools to have insight into your business. Focus&rsquo; cloud reporting and management platform consolidates data from multiple locations so that you can access it from one convenient dashboard. With Focus, you can evaluate individual store performance, make comparisons between locations, or examine business performance overall.</p>
						<p>Focus enables you to access store-level reports, monitor trends, create graphs, and even build a custom dashboard for a unique view of critical data. Focus cloud reporting and management also integrates with Focus Enterprise to allows easy menu updates at store level, mobile devices and online.</p>
						<p>Once you have the data you need to make informed decisions, you can use those insights to make menu changes, add employees, and edit time cards anytime and anywhere, all from the cloud. Focus brings full on-premises functionality to the cloud. Ideal for both multi-store chains as well as single locations, restaurant operators can manage business while on the go. And, because the cloud does not require an always-on internet connection, an internet outage won’t cause your operations to cease.</p>
					</div>
				</div>
				<div className='column'>
					<img src={image1src} alt='image1'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#015491'}}>
				<div className='placard'>
					<h2 style={{color: '#fff'}}>Want to learn more about Focus POS?</h2>
					<a className='widget-link white' href='https://www.focuspos.com/wp-content/uploads/2020/07/Focus_2020-Company-Brochure_final.pdf' target='blank'>
						Download Company Brochure
					</a>
				</div>
			</div>
			<div className='row-2'>
				<div className='column'>
					<img src={image2src} alt='image2'/>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>The Easy Way to Manage Stores Remotely</h2>
						<p>Access Focus cloud reporting and management tools through an internet browser to manage your enterprise or single location.</p>
						<div className='row-2' style={{padding: 0}}>
							<div className='column'>
								<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700'}}>Enterprise Dashboard</p>
								<ul>
									<li>Region filters</li>
									<li>Date range spans</li>
									<li>Store averages</li>
									<li>Graphical analysis</li>
									<li>Store alerts</li>
									<li>System status</li>
								</ul>
							</div>
							<div className='column'>
								<p style={{color: '#02598f', fontSize: '28px', fontWeight: '700'}}>Single Store Dashboard</p>
								<ul>
									<li>Drill-down details</li>
									<li>Graphical data</li>
									<li>Store alerts</li>
									<li>Last batch information</li>
									<li>Employees on the clock</li>
									<li>Tracking items</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Cloud Reporting Features</h2>
						<ul>
							<li>Enterprise Reports</li>
							<ul>
								<li>Advertising and royalty</li>
								<li>Corporate item and labor</li>
							</ul>
							<li>Store Reports</li>
							<ul>
								<li>Labor</li>
								<li>Menu items</li>
								<li>Accounting Interface</li>
							</ul>
						</ul>
					</div>
				</div>
				<div className='column'>
					<img src={image3src} alt='image3'/>
				</div>
			</div>
			<div className='row-2'>
				<div className='column'>
					<img src={image4src} alt='image4'/>
				</div>
				<div className='column'>
					<div className='text-section'>
						<h2>Cloud Management Features</h2>
						<ul>
							<li>Edit timecards from anywhere</li>
							<li>Make menu changes from your home office</li>
							<li>Manage single store locations and large multi-unit chains</li>
							<li>Remotely manage labor</li>
							<li>Pull labor and sales reports</li>
							<li>Create and edit employees</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column'>
					<h2>Software Screenshots</h2>
					<p style={{color: '#02598f', fontSize: '28px', fontStyle: 'italic', fontWeight: '400'}}>Click on any screenshot below to enlarge</p>
					<FPMImgGalery imgList={glImageList}/>
				</div>
			</div>
		</>);
}

export default CloudRepMng;