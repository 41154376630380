import FPMPageTitle from '../components/FPM-PageTitle';

const ContactUs = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{padding: 0}}>
				<FPMPageTitle title='Contact Us' />
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4', justifyContent: 'center'}}>
				
					<form className='column'>
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
							<label htmlFor="contactname">Name*</label>
							<input type="text" name="contactname" id="contactname" required />
							<label htmlFor="contactcompany">Company*</label>
							<input type="text" name="contactcompany" id="contactcompany" required />
							<label htmlFor="contactemail">Email*</label>
							<input type="email" name="contactemail" id="contactemail" required />
							<label htmlFor="contactphone">Phone</label>
							<input type="tel" name="contactphone" id="contactphone" />
							<label htmlFor="contactrequest">How Can We Help?</label>
							<textarea name="contactrequest" id="contactrequest" required />
							<input type="submit" />
						</div>
					</form>
			</div>
			
		</>);
}

export default ContactUs;