import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeGalery from '../components/FPM-YouTubeGalery';

const videoList1 = {
	title: null, 
	list: [
		{caption: 'Onscreen Tablist', ID: 'L78uXJEazNw'},
		{caption: 'Transfer Items to New Table or Tab', ID: 'Y7t5FiFhPZ8'},
		{caption: 'Post Points at Payment & Update Customer info from the POS', ID: 'CrvYSqht2H0'},
		{caption: 'Menu Item DB Management', ID: 'ny2M8fS5DgU'},
		{caption: 'Guest Check Formatted Items', ID: 'QAyX4YA3hgs'},
		{caption: 'Back Office Shortcuts', ID: 'U6ov2slhP4g'},
		{caption: 'Remove check from canvases', ID: 'gRXNZFlBCyM'},
		{caption: 'Manage Variable Priced / Open Items', ID: '3zMvwX6eE9I'},
		{caption: 'Windows Shortcuts for Back Office', ID: '0ZXzU2OKOCs'},
		{caption: 'Table Turns', ID: 'tg1CR9ny8DM'},
		{caption: 'Focus POS Systems Waitlist', ID: 'PIGQ27XMt8o'},
		{caption: 'Super Groups on Activity Report', ID: 'qBSxszBJYAk'},
		{caption: 'Waitlist Demo', ID: 'HxvZHhHKA8w'},
		{caption: 'Open Menu Items', ID: 'C614W7Tz6Xo'},
		{caption: 'Conventional Time', ID: 'Mwg8rRCSORU'},
		{caption: 'Job Right Export', ID: 'OcGXJZ1JVOI'},
		{caption: 'Waitlist', ID: 'HcnzyIRWP2M'},
		{caption: 'Waitlist', ID: 'QU3MA7f61vI'},
		{caption: 'Focus Shortcuts', ID: '_Nx0NyZZdPI'},
		{caption: 'Transfer to a Table', ID: '9ago-UZ89UA'},
	],
};

const Videos = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{padding: 0}}>
				<FPMPageTitle title='Videos' />
			</div>
			<div className='row' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyCosntent: 'flex-start'}}>
					<FPMYouTubeGalery videoList={videoList1}/>
				</div>
			</div>
		</>);
}

export default Videos;