import {FaAngleRight} from 'react-icons/fa';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMFeatureList from '../components/FPM-FeatureList';
import image1src from '../images/ice-cream2.png';
import image2src from '../images/ice-cream3.png';
import image3src from '../images/ice-cream4.png';
import image4src from '../images/ice-cream1.png';
import image5src from '../images/partner_worldpay.jpg';
import image6src from '../images/ice-cream-waffle-cones-frozen-yogurt-pos.png';
import image7src from '../images/mockup_4-new-must-have-technologies-for-your-restaurant.png';
import image8src from '../images/mockup_5-ways-tablet-pos-increases-restaurant-revenue.png';
import image9src from '../images/mockup_calculate-restaurant-roi-from-pos.png';
import image10src from '../images/Focus_future-proof-your-restaurant_book2.png';

const list1 = [
	{text: `Turnover orders quickly by simplifying your checkout process`},
	{text: `Ensure prices and orders are correct with NTEP certified food-safe scales`},
	{text: `Offer suggestive selling, tip prompts, and sign-on screen for credit card payments`},
	];

const list2 = [
	{text: `Smart and printable recipes so employees can create fan-favorite menu items with ease`},
	{text: `Employee schedule management to keep you and your staff organized and up-to-date`},
	{text: `Short training times: our intuitive interface is easy to use, helping new employees learn even on the busiest days`},
	];

const list3 = [
	{text: `Inventory tracking to prevent any shortages`},
	{text: `Remote monitoring of sales and labor information`},
	{text: `Complete visibility and real-time reporting over cash shortages and comps and voids`},
	];

const list4 = [
	{text: `As an innovative mobile solution, FocusON can help you take your ice cream parlor on the road. It gives you the power to process transactions and keep up with orders wherever and whenever you go`},
	{text: `Our Multi-Check Dashboard keeps your staff organized by effortlessly managing several checks on one screen, eliminating the chance for potential errors`},
	{text: `myFocus Loyalty gives your customers a reason to come back for more scoops of their favorite ice cream. Reward your most loyal customers with coupons, points, and other promotional items to establish lasting relationships`},
	];

const list5 = [
	{text: `Accessibility to onsite sales and service couldn’t be faster and simpler`},
	{text: `Choose your partners to help you process and control credit card fees`},
	{text: `The best and most reliable software around`},
	];

const IceCreamPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Ice Cream POS' />
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Scoop Up More Revenue With Our Ice Cream POS</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>For ice cream shop owners, quickly serving customers is the second most crucial part of the business – next to the ice cream itself. People get ice cream when they want to treat themselves, and long lines and melted ice cream could spoil the occasion. Therefore, ice cream shop owners should consider purchasing ice cream POS software to create a more efficient and profitable establishment.</p>
						<p>Focus’s software for ice cream parlors is ideal for operations of any size. In addition to streamlining the ordering process, an ice cream point of sale system allows business owners to track inventory, employee hours, and sales. It even helps you create loyalty programs. Of course, it’s always good to know how your sales are doing, and identifying best sellers is essential for keeping up with customer demand.</p>
						<p>In this day and age, it’s critical to operate a business with a reliable, feature-rich point-of-sale system. Focus POS is a trusted name in the restaurant industry, and the same can be said about desserts. Focus understands that every business has unique qualities, so every ice cream POS system is designed explicitly for individual shops.</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='SAlMIMqASaE' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Provide Exceptional Service to Your Ice Cream Fanatics'
							description='The last thing your customers want is to wait for ice cream that’s already melted. Keep your customers happy by getting them out the door before they can say “chocolate-vanilla soft serve!” with an ice cream point of sale. Our solution can help you:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list1}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Support Your Employees and Keep the Shop Running Smoothly'
							description='On hot summer days, it’s challenging to handle the sudden demand for frozen treats. Take the strain off your staff and business by letting our software for ice cream parlors do all the heavy lifting. Improve your operations with features, such as:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list2}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Make Better-Informed Decisions With Data Analytics'
							description='As competition continues to grow, you need to consider all your options to make your ice cream parlor stand out from all the rest. With back-end reporting, data collection, and statistical analysis, our ice cream point of sale can make all your future goals a reality. Our software includes features like:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list3}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Enhance Your Ice Cream POS Restaurant Management Software'
							description='Besides possessing all the basics for Focus POS Restaurant Management Software, you can enhance your customers’ ice cream parlor experience by adding other robust solutions.'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list4}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>Customers</h2></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image5' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image6' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image7' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image8' style={{maxWidth: '250px'}}/></div>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Why Choose Focus POS Software for Ice Cream Parlors?'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list5}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image6src} alt='image9'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>E-Books</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image7src} alt='image10' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>4 NEW Must-Have Technologies for Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/4-new-must-have-technologies-for-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image8src} alt='image11' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>5 Ways Tablet POS Increases Restaurant Revenue</p>
							<a className='widget-link' href='https://www.focuspos.com/5-ways-tablet-pos-increases-restaurant-revenue/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image9src} alt='image12' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>How to Calculate Your Restaurant’s ROI from a POS Deployment</p>
							<a className='widget-link' href='https://www.focuspos.com/how-to-calculate-your-restaurants-roi-from-a-pos-deployment/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image10src} alt='image13' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Ways to Future-Proof Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/6-ways-to-future-proof-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default IceCreamPOS;