import {Link} from 'react-router-dom';
import FPMDropDownMenuBody from './FPM-DropDownMenuBody'

const FPMDropDownMenu = ({id, item, selected, onSelect}) => {
	return(
		<div className='dropdown-menu-div'>
			<div className='dropdown-menu-header'>
				<Link className={selected.menu != null && id === selected.menu ? 'selected' : null}
					to={item.href}
					onClick={() => onSelect(id, item.defaultItemId)}>
						{item.text}
				</Link>
			</div>
			{item.menuItems.length > 0 && <FPMDropDownMenuBody id={id} menuItems={item.menuItems} selected={selected} onSelect={onSelect}/>}
		</div>
	);
}

export default FPMDropDownMenu;
