import FPMSocialItem from './FPM-SocialItem'

const FPMSocialList = ({socialItems}) => {
	return(
		<div className='social-list'>
			{
				socialItems.map((item, index) => {
					return(
					<FPMSocialItem
						key={index}
						id={index}
						item={item}
					/>);
				})
			}
		</div>
	)
}

export default FPMSocialList
