import {FaAngleRight} from 'react-icons/fa';
import FPMPageTitle from '../components/FPM-PageTitle';
import FPMYouTubeClip from '../components/FPM-YouTubeClip';
import FPMFeatureList from '../components/FPM-FeatureList';
import image1src from '../images/eating-ice-cream-frozen-yogurt-pos.png';
import image2src from '../images/ice-cream-waffle-cones-frozen-yogurt-pos.png';
import image3src from '../images/frozen-yogurt3.png';
import image4src from '../images/frozen-yogurt4.png';
import image5src from '../images/Flogo.gif';
import image6src from '../images/Cold_Stone_Creamery-Logo.wine_.png';
import image7src from '../images/Kahwa-Coffee.png';
import image8src from '../images/sunrise-titles_8d1ccfb3-b592-493b-9a8b-15e523cdf8f9_600x.png';
import image9src from '../images/frozen-yogurt1.png';
import image10src from '../images/mockup_4-new-must-have-technologies-for-your-restaurant.png';
import image11src from '../images/mockup_5-ways-tablet-pos-increases-restaurant-revenue.png';
import image12src from '../images/mockup_calculate-restaurant-roi-from-pos.png';
import image13src from '../images/Focus_future-proof-your-restaurant_book2.png';

const list1 = [
	{text: `Simplify the checkout process and keep orders flowing without missing a beat. You can customize your menu boards so you can locate popular items in seconds`},
	{text: `Manage customer loyalty and gift cards; reward your most faithful customers and create future regulars with coupons, promotions, points, and more`},
	{text: `Organize and keep track of inventory so you don’t run out of customers’ favorite toppings and other ingredients`},
	];

const list2 = [
	{text: `Integration with food-safe NTEP-certified scales`},
	{text: `Options for printable and smart recipe viewing`},
	{text: `Efficient employee scheduling tools`},
	{text: `An intuitive interface that allows your employees to be trained quickly and efficiently`},
	];

const list3 = [
	{text: `Remote monitoring and text message alerts`},
	{text: `Access to real-time reports to monitor cash shortages, inventory shrinkage, comps and voids`},
	{text: `Pull labor and sales data to help you create a better strategy for the future`},
	];

const list4 = [
	{text: `FocusPay can integrate into your POS system so that you can safely EMV transactions, providing your customers with more ways to pay`},
	{text: `myFocus Loyalty helps you engage customers even when they’re not at your frozen yogurt shop. You can send emails with coupons and other marketing promotions as incentives to keep them coming back for more`},
	{text: `Focus Mobile Alerts allow you to manage your shop and access vital information whenever and wherever you go on your mobile device`},
	];

const list5 = [
	{text: `Quick and easy retrieval to onsite service and sales`},
	{text: `Autonomy to operate with your partners to manage and control credit card fees`},
	{text: `Highly dependable software`},
	];

const FrozYogurtPOS = ({onSelect}) => {
	return (
		<>
			<div className='row' style={{paddingTop: 0}}>
				<FPMPageTitle title='Frozen Yogurt POS' />
			</div>
			<div className='row' style={{paddingBottom: 0, backgroundColor: '#fff'}}>
				<div className='column'>
					<div className='text-section'>
						<h2>Business is Sweeter With Our Frozen Yogurt POS</h2>
					</div>
				</div>
			</div>
			<div className='row-2' style={{paddingTop: 0, backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<p>When your customers want a creamy and flavorful yogurt dessert or smoothie, they need a frozen yogurt shop that can bring the heat without melting their delicious treats. A frozen yogurt POS system from Focus POS can help you become a fan favorite quickly and effortlessly.</p>
						<p>Customers love customizing their frozen creations with various toppings, candies, and mix-n-match flavors; with our frozen yogurt software, your business can keep inventory organized so you’ll never run out of popular ingredients. In addition, integration with food-safe scales that are NTEP-certified allows your customers to pay by the ounce, helping you streamline business operations.</p>
						<p>During the busiest season, like on sweltering days when customer traffic is at its peak, our software for frozen yogurt shops allows your employees to take quick and accurate transactions, keeping orders flowing and customer wait times at a minimum. At Focus POS, we’ll help you put more money in your pocket and more frozen sweets in your customers’ bellies!</p>
					</div>
				</div>
				<div className='column'>
					<FPMYouTubeClip videoId='SAlMIMqASaE' aspectX={16} aspectY={9}/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image1src} alt='image1'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Look After Your Frozen Yogurt Lovers'
							description='Get your customers through the checkout and out the door before their frozen desserts melt with our frozen yogurt POS system. Our point of sale solution can help you:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list1}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Supervise Your Staff and Business Operations'
							description='Give your customers a memorable experience, full of delightful treats and excellent customer service, by providing your staff with easy-to-use software for frozen yogurt shops. Our POS solution includes features, such as:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list2}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image2src} alt='image2'/>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image3src} alt='image3'/>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Gain Valuable Insights with Frozen Yogurt POS'
							description='Keep a thumb on the pulse of your operations with our frozen yogurt software. Maintain complete visibility over your shop with powerful back-end reporting and data analysis, all from your POS system. Key features include:'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list3}
						/>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Enhance Your Frozen Yogurt POS Restaurant Management Software'
							description='Along with the Focus POS Restaurant Management Software basics, you can provide a more enriched experience for your customers by adding our other state-of-the-art solutions.'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list4}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image4src} alt='image4'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>Customers</h2></div>
						<div className='wall-item'><img className='exp-img' src={image5src} alt='image5' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image6src} alt='image6' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image7src} alt='image7' style={{maxWidth: '250px'}}/></div>
						<div className='wall-item'><img className='exp-img' src={image8src} alt='image8' style={{maxWidth: '250px'}}/></div>
					</div>
				</div>
			</div>
			<div className='row-2' style={{backgroundColor: '#fff'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='text-section'>
						<FPMFeatureList
							title='Why Choose Focus POS Software for Frozen Yogurt Shops?'
							listBullet={<FaAngleRight className='li-img'/>}
							itemList={list5}
						/>
					</div>
				</div>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<img className='exp-img' src={image9src} alt='image9'/>
				</div>
			</div>
			<div className='row' style={{backgroundColor: '#f4f4f4'}}>
				<div className='column' style={{justifyContent: 'flex-start'}}>
					<div className='wall-4'>
						<div className='wall-title'><h2>E-Books</h2></div>
						<div className='wall-item'>
							<img className='exp-img' src={image10src} alt='image10' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>4 NEW Must-Have Technologies for Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/4-new-must-have-technologies-for-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image11src} alt='image11' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>5 Ways Tablet POS Increases Restaurant Revenue</p>
							<a className='widget-link' href='https://www.focuspos.com/5-ways-tablet-pos-increases-restaurant-revenue/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image12src} alt='image12' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>How to Calculate Your Restaurant’s ROI from a POS Deployment</p>
							<a className='widget-link' href='https://www.focuspos.com/how-to-calculate-your-restaurants-roi-from-a-pos-deployment/' target='blank'>
								Download E-Book
							</a>
						</div>
						<div className='wall-item'>
							<img className='exp-img' src={image13src} alt='image13' style={{maxWidth: '250px', marginBottom: 0}}/>
							<p style={{fontWeight: 700, textAlign: 'center', maxWidth: '275px'}}>6 Ways to Future-Proof Your Restaurant</p>
							<a className='widget-link' href='https://www.focuspos.com/6-ways-to-future-proof-your-restaurant/' target='blank'>
								Download E-Book
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FrozYogurtPOS;